import { Typography } from '@mui/material'
import React from 'react'
import AircraftIcon from '@mui/icons-material/AirplanemodeActiveRounded'
import InlinePopoverField from './InlinePopoverField'
import { formatDateForDisplay } from 'src/utils/helpers'
import type { User } from 'types/graphql'
import { abbreviateFullName } from 'src/utils/stringFormatters'

interface WorkflowHeaderProps {
  title: string
  createdAt?: string | Date
  createdBy?: User
  updatedAt?: string | Date
  tailNumber?: string
  slots?: {
    inlineFields?: React.ReactNode
    topRightFields?: React.ReactNode
    statusChip?: React.ReactNode
  }
}

const WorkflowHeader: React.FC<WorkflowHeaderProps> = ({
  title,
  createdAt,
  createdBy,
  updatedAt,
  tailNumber,
  slots = {},
}) => {
  const { inlineFields, topRightFields, statusChip } = slots

  return (
    <div className="flex flex-col gap-0.5 p-4 pb-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Typography variant="h5" component="h1">
            {title}
          </Typography>
          {statusChip}
        </div>

        <div className="flex items-center gap-2">
          {updatedAt && (
            <Typography variant="subtitle1">
              updated at {formatDateForDisplay(updatedAt)}
            </Typography>
          )}
          {topRightFields}
        </div>
      </div>
      {createdAt && (
        <Typography variant="body1" component="p">
          Created on {formatDateForDisplay(createdAt)}
          {createdBy && <> by {abbreviateFullName(createdBy)}</>}
        </Typography>
      )}
      <div className="flex items-center gap-2">
        {tailNumber && (
          <InlinePopoverField
            label="AC Tail"
            value={tailNumber}
            icon={AircraftIcon}
          />
        )}
        {inlineFields}
      </div>
    </div>
  )
}

export default WorkflowHeader
