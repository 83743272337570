import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Chip, Typography, Popover, Tooltip } from '@mui/material'
import { HighlightedTypography } from './MUI/StyledComponents'

export interface ChipData {
  label: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

interface TypeAndDescriptionDisplayProps {
  description: string
  chips?: ChipData[]
  tailTags?: string[]
  maxRows?: number
  highlight?: boolean
}

// Move static objects outside component
const popoverOrigin = {
  vertical: 'center',
  horizontal: 'center',
} as const

// Create memoized sub-components
const ChipsDisplay = React.memo(({ chips }: { chips?: ChipData[] }) => (
  <div>
    {chips?.map((chip) => (
      <Chip
        key={chip.label}
        label={chip.label}
        className="mr-[5px] w-fit"
        size="small"
        onClick={chip.onClick}
        {...(chip.onClick && { style: { cursor: 'pointer' } })}
      />
    ))}
  </div>
))

ChipsDisplay.displayName = 'ChipsDisplay'

const TailTagsDisplay = React.memo(({ tailTags }: { tailTags?: string[] }) => (
  <div className="flex flex-col items-end">
    {tailTags?.map((tag) => (
      <Typography key={tag} variant="caption" component={'div'}>
        {tag}
      </Typography>
    ))}
  </div>
))

TailTagsDisplay.displayName = 'TailTagsDisplay'

// Main component with optimizations for virtualization
const TypeAndDescriptionDisplay = React.memo<TypeAndDescriptionDisplayProps>(({
  description,
  chips,
  tailTags,
  maxRows = 2,
  highlight = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const popoverButtonRef = useRef<HTMLDivElement>(null)
  const [popoverButtonWidth, setPopoverButtonWidth] = useState(0)

  // Single useEffect for measurements
  useEffect(() => {
    const currentDescRef = descriptionRef.current
    const currentButtonRef = popoverButtonRef.current

    if (currentDescRef && currentButtonRef) {
      setIsTruncated(currentDescRef.clientHeight < currentDescRef.scrollHeight)
      setPopoverButtonWidth(currentButtonRef.clientWidth)
    }
  }, [description]) // Only re-run when description changes

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const DescriptionContent = useMemo(() =>
    highlight ? (
      <HighlightedTypography variant="body2">{description}</HighlightedTypography>
    ) : (
      <Typography variant="subtitle2">{description}</Typography>
    )
  , [description, highlight])

  const contentDisplay = useMemo(() => (
    <div
      ref={popoverButtonRef}
      className="flex flex-col"
    >
      <div className="flex flex-row items-center">
        <div className="min-w-0 flex-1">
          <div ref={descriptionRef} className="line-clamp-2">
            {DescriptionContent}
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-between">
        {chips && <ChipsDisplay chips={chips} />}
        {tailTags && <TailTagsDisplay tailTags={tailTags} />}
      </div>
    </div>
  ), [DescriptionContent, chips, tailTags])

  return (
    <div>
      {isTruncated ? (
        <div>
          <Tooltip title="Click to expand">
            <div onClick={handlePopoverOpen}>
              {contentDisplay}
            </div>
          </Tooltip>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClick={(e) => e.stopPropagation()}
            onClose={handlePopoverClose}
            anchorOrigin={popoverOrigin}
            transformOrigin={popoverOrigin}
          >
            <div
              style={{ width: popoverButtonWidth }}
              className="p-2"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex flex-col">
                {DescriptionContent}
                <div className="flex w-full items-center justify-between">
                  {chips && <ChipsDisplay chips={chips} />}
                  {tailTags && <TailTagsDisplay tailTags={tailTags} />}
                </div>
              </div>
            </div>
          </Popover>
        </div>
      ) : contentDisplay}
    </div>
  )
})

TypeAndDescriptionDisplay.displayName = 'TypeAndDescriptionDisplay'

export default TypeAndDescriptionDisplay
