import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { TableRow, TableCell, Checkbox, Typography, Paper } from '@mui/material'
import { MaintenanceItem } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import DropdownButton from 'src/components/MUI/DropdownButton'
import IconButton from 'src/components/MUI/IconButton'
import Table, {
  CheckboxHeader,
  DataProp,
  useCheckboxSelection,
} from 'src/components/Table'
import useAircraftList from 'src/hooks/requests/useAircraftRecords'
import { useOrgName } from 'src/hooks/useOrgName'

import CORE_MAINTENANCE_DUE_STATUS_CALC_FRAGMENT from 'src/fragments/MaintenanceItem'
import { fakeData } from 'src/layouts/AppLayout/AiAssistantDrawer/ActionHandlers/fakeData'

const GET_MAINTENANCE_ITEMS_BY_IDS = gql`
  ${CORE_MAINTENANCE_DUE_STATUS_CALC_FRAGMENT}
  query GetMaintenanceItemsByIds($ids: [String!]!) {
    maintenanceItems(ids: $ids) {
      id
      ataCode
      manufactureCode
      title
      aircraftComponent {
        partNumber
        serialNumber
      }
      aircraftId
      ...CoreMaintenanceDueStatusCalcFields
    }
  }
`

export type RowData = DataProp & MaintenanceItem

type SelectedTasksListProps = {
  selectedTasks: string[]
  onAddTaskClick: () => void
  onRemoveTasksClick: (selectedTaskIds: readonly string[]) => void
}

const SelectableRow = ({
  data: row,
  handleSelect,
  selected,
}: {
  data: RowData
  handleSelect: (id: string) => void
  selected: string[]
  setShowEditWorkItemModal: (value: boolean) => void
  setRowDataToEdit: (value: RowData) => void
}) => {
  const { aircrafts, loadAircraftById } = useAircraftList()
  useEffect(() => {
    if (row.aircraftId) {
      loadAircraftById(row.aircraftId)
    }
  }, [row.aircraftId])

  // const aircraft = aircrafts[row.aircraftId]

  const aircraft = fakeData.aircraft

  const orgName = useOrgName()

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={selected.includes(row.id)}
          onClick={() => handleSelect(row.id)}
        />
      </TableCell>
      <TableCell>
        {row.ataCode} / {row.manufactureCode}
      </TableCell>
      <TableCell>{row.title}</TableCell>
      <TableCell>
        <div className="flex flex-col">
          {row.aircraftComponent?.partNumber && (
            <Typography variant="caption">
              Part: {row.aircraftComponent?.partNumber}
            </Typography>
          )}
          {row.aircraftComponent?.serialNumber && (
            <Typography variant="caption">
              Serial: {row.aircraftComponent?.serialNumber}
            </Typography>
          )}
        </div>
      </TableCell>
      <TableCell>
        <DueStatusChip maintenanceItem={row} aircraft={aircraft} />
      </TableCell>
      <TableCell>
        <div className="flex gap-1">
          <IconButton
            onClick={() =>
              navigate(routes.maintenanceItem({ orgName, id: row.id }))
            }
          >
            <ArrowForwardRoundedIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

const SelectedTasksList: React.FC<SelectedTasksListProps> = ({
  selectedTasks,
  onAddTaskClick,
  onRemoveTasksClick,
}) => {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')
  const [orderBy, setOrderBy] = React.useState<string>(undefined)
  const [fetchMaintenanceItems, { data: selectedMaintenanceItems }] =
    useLazyQuery(GET_MAINTENANCE_ITEMS_BY_IDS)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // TODO: wwAI commented out for now to demo fake data
  // React.useEffect(() => {
  //   if (selectedTasks) {
  //     fetchMaintenanceItems({
  //       variables: {
  //         ids: selectedTasks,
  //         sortField: orderBy,
  //         sortOrder: order,
  //       },
  //     })
  //   }
  // }, [selectedTasks, order, orderBy])

  // const { maintenanceItems } = selectedMaintenanceItems ?? {}

  // const { selected, handleSelect, handleSelectAll, handleUnselectAll } =
  //   useCheckboxSelection(maintenanceItems ?? [])

  const { maintenanceItems } = fakeData

  const { selected, handleSelect, handleSelectAll, handleUnselectAll } =
    useCheckboxSelection(fakeData.maintenanceItems)

  const Actions = () => {
    return (
      <>
        <DropdownButton
          variant="outlined"
          color="base"
          options={[
            {
              label: 'Add Task(s)',
              onClick: onAddTaskClick,
              disabled: true,
            },
            {
              label: 'Remove Task(s)',
              onClick: () => onRemoveTasksClick(selected),
              disabled: true,
            },
          ]}
        >
          More Options
        </DropdownButton>
      </>
    )
  }

  return (
    <Paper className="pb-2 pt-2">
      <Table
        title="Included tasks"
        container="fragment"
        data={maintenanceItems ?? []}
        slots={{
          Row: SelectableRow,
          Actions,
        }}
        RowProps={{
          handleSelect,
          selected,
        }}
        columnConfig={[
          {
            id: 'selected',
            component: (
              <CheckboxHeader
                selectedCount={selected.length}
                rowCount={maintenanceItems?.length}
                handleSelectAll={handleSelectAll}
              />
            ),
          },
          { id: 'ataCode', label: 'ATA/code' },
          { id: 'typeDescription', label: 'Type' },
          { id: 'partSerialNumber', label: 'Part, Serial#' },
          { id: 'calculatedNextDueAt', label: 'Remaining' },
          { id: 'actions', label: 'Actions' },
        ]}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        sortableColumns={[
          'ataCode',
          'typeDescription',
          'workedById',
          'estimatedWorkMinutes',
          'estimatedRate',
          'calculatedNextDueAt',
        ]}
      />
    </Paper>
  )
}

export default SelectedTasksList
