import { RootState } from '../reducers'
import { setNavDrawerExpanded as setNavDrawerExpandedInSlice, setAiAssistantDrawerOpen as setAiAssistantDrawerOpenInSlice } from '../slices/appLayoutSlice'

import { useDispatch } from './useDispatch'
import { useSelector } from './useSelector'

interface WorkCompletedTableFiltersHook {
  navDrawerExpanded: boolean
  aiAssistantDrawerOpen: boolean
  setAiAssistantDrawerOpen: (isOpen: boolean) => void
  setNavDrawerExpanded: (isExpanded: boolean) => void
}

const useAppLayoutState = (): WorkCompletedTableFiltersHook => {
  const dispatch = useDispatch()
  const navDrawerExpanded: boolean = useSelector(
    (state: RootState) => state.appLayout.navDrawerExpanded
  )

  const setNavDrawerExpanded = (isExpanded: boolean): void => {
    dispatch(setNavDrawerExpandedInSlice(isExpanded))
    }

  const aiAssistantDrawerOpen: boolean = useSelector(
    (state: RootState) => state.appLayout.aiAssistantDrawerOpen
  )
  const setAiAssistantDrawerOpen = (isOpen: boolean): void => {
    dispatch(setAiAssistantDrawerOpenInSlice(isOpen))
  }

  return {
    navDrawerExpanded,
    setNavDrawerExpanded,
    aiAssistantDrawerOpen,
    setAiAssistantDrawerOpen,
  }
}

export default useAppLayoutState
