import { Chip as MuiChip, styled } from '@mui/material'
import { ChipProps as MuiChipProps } from '@mui/material/Chip'

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true
  }
}

// Extend MUI's ChipProps to allow additional colors while keeping original ones
interface CustomChipProps extends MuiChipProps {}

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => !['variant'].includes(prop as string),
})<CustomChipProps>(({ theme, color, variant }) => ({
  // Base styles to match Figma design
  height: '22px',
  padding: '2px 10px',
  borderRadius: '6px',

  // Increase specificity for label styles
  '& .MuiChip-label': {
    padding: '0',
    fontSize: '12px',
    lineHeight: '150%',
    fontWeight: 500,
  },

  // Add soft variant styles using theme colors
  ...(variant === 'soft' && {
    backgroundColor: theme.palette[color]?.light || theme.palette.grey[200],
    color: theme.palette[color]?.dark || theme.palette.text.primary,
  }),
  // Preserve outlined variant styles
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    border: `1px solid ${
      theme.palette[color]?.main || theme.palette.grey[500]
    }`,
    color: theme.palette[color]?.main || theme.palette.text.primary,
  }),
}))

const Chip = (props: CustomChipProps) => {
  return <StyledChip {...props} />
}

export default Chip
