import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Sentry from 'src/lib/sentry'

import { useOrgName } from '../useOrgName'

import useUploadFile from './useUploadFile'

const UPSERT_MTX_LOGBOOK = gql`
  mutation UpsertMtxLogbook(
    $id: String!
    $input: UpsertMaintenanceLogbookInput!
  ) {
    upsertMaintenanceLogbook(id: $id, input: $input) {
      id
      complianceLedger {
        id
        requestedWorkerId
        requestedInspectorId
      }
    }
  }
`

const BULK_UPSERT_MTX_LOGBOOK_ACTIVITY = gql`
  mutation BulkUpsertMtxLogbookActivity(
    $input: BulkUpsertMaintenanceLogbookActivityInput!
  ) {
    bulkUpsertMaintenanceLogbookActivity(input: $input) {
      id
      logbookId
      complianceActivityId
    }
  }
`

const UPSERT_MTX_LOGBOOK_TRANSACTION = gql`
  mutation UpsertMtxLogbookTransaction(
    $id: String!
    $input: UpsertMaintenanceLogbookTransactionInput!
  ) {
    upsertMaintenanceLogbookTransaction(id: $id, input: $input) {
      id
      complianceLedger {
        id
        requestedWorkerId
        requestedInspectorId
      }
    }
  }
`

interface CreateResult {
  success: boolean
  upsertMaintenanceLogbook: any
}

const useCreateLogbookEntry = () => {
  // v1
  const [upsertMtxLogbook] = useMutation(UPSERT_MTX_LOGBOOK)
  const [bulkUpsertMtxLogbookActivity] = useMutation(
    BULK_UPSERT_MTX_LOGBOOK_ACTIVITY
  )

  // v2
  const [upsertMtxLogbookTransaction] = useMutation(
    UPSERT_MTX_LOGBOOK_TRANSACTION
  )

  const uploadFile = useUploadFile()

  const orgName = useOrgName()

  const somethingWentWrong = (error, message = '.') => {
    console.error(error)
    Sentry.captureException(error)
    toast.error(`Something went wrong${message} Please try again later.`, {
      duration: 3000,
    })
  }

  const handleCreateLogbookEntry = async (input): Promise<CreateResult> => {
    const {
      workDetails = [],
      attachments,
      logbookId,
      ...mtxLogbookData
    } = input

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mtxLogbookResponse: any = await upsertMtxLogbook({
        variables: {
          id: logbookId,
          input: { orgSlug: orgName, ...mtxLogbookData },
        },
      })

      const newLogbookId = mtxLogbookResponse.data.upsertMaintenanceLogbook.id
      await bulkUpsertMtxLogbookActivity({
        variables: {
          input: {
            orgSlug: orgName,
            logbookId: newLogbookId,
            activityItems: workDetails,
          },
        },
      })

      if (attachments) {
        Array.from(attachments).forEach(async (attachment: File) => {
          await uploadFile(attachment, newLogbookId, 'Logbook')
        })
      }

      return {
        success: true,
        upsertMaintenanceLogbook: mtxLogbookResponse,
      }
    } catch (error) {
      somethingWentWrong(error)
      return {
        success: false,
        upsertMaintenanceLogbook: null,
      }
    }
  }

  const handleCreateLogbookEntryV2 = async (input) => {
    const {
      workDetails = [],
      attachments,
      logbookId,
      ...mtxLogbookData
    } = input

    try {
      const mtxLogbookResponse = await upsertMtxLogbookTransaction({
        variables: {
          id: logbookId,
          input: {
            orgSlug: orgName,
            activityItems: workDetails,
            ...mtxLogbookData,
          },
        },
      })

      return {
        success: true,
        upsertMaintenanceLogbook: mtxLogbookResponse,
      }
    } catch (error) {
      somethingWentWrong(error)
      return {
        success: false,
        upsertMaintenanceLogbook: null,
      }
    }
  }

  return {
    createLogbookEntry: handleCreateLogbookEntry,
    createLogbookEntryV2: handleCreateLogbookEntryV2,
  }
}

export default useCreateLogbookEntry
