import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  DueListPrevDeps,
  DueListTableFilterData,
  DuelistFilterProjection,
  DuelistGeneralFilterData,
  SelectedInProgress,
} from 'src/types'
import { isNotEmpty } from 'src/utils/helpers'

import { reset } from '../sharedActions'
import { MaintenanceItem } from 'types/graphql'
import { isMaintenanceItemInProgress } from 'src/utils/maintenanceItem'

export const initialProjectionFilterData: DuelistFilterProjection = {
  // date: dayjs().format('MM/DD/YYYY'),
  date: undefined,
  projectionGrid: [
    {
      id: 1,
      metric: 'Landings',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 2,
      metric: 'Hours',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 3,
      metric: 'Cycles',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 4,
      metric: 'APU Hours',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
  ],
}

export const initialGeneralFilterData: DuelistGeneralFilterData = {
  isOptional: false,
  isPartBased: false,
  statuses: null,
  cadenceType: '',
  isAdSb: false,
}


export const initialFilterData: DueListTableFilterData = {
  fuzzySearchTerms: '',
  powerSearchTerms: {},
  aircrafts: {},
  dueStatus: {
    overdue: false,
    inTolerance: false,
    notInDue: false,
  },
  interval: {
    flyingHours: false,
    day: false,
    month: false,
    landing: false,
    takeoff: false,
  },
  assignedMechanic: {},
  // TODO: all of the timeFrame objects are the same filter so they should be under the same property
  timeFrameAll: false,
  timeframeDays: {
    option: '',
    days: -1,
  },
  timeframeHours: {
    hours: -1,
    option: '',
  },
  timeframeCycles: {
    cycles: -1,
    option: '',
  },
  timeframeLandings: {
    landings: -1,
    option: '',
  },
  filterDataBy: [],
  projectionFilterData: initialProjectionFilterData,
  tableRefreshKey: 0,
  generalFilterData: initialGeneralFilterData,
  ataCodes: [],
  trackedBy: [],
}

export const initialDuelistPrevDeps: DueListPrevDeps = {
  fuzzySearchTerms: '',
  powerSearchTerms: {},
  aircrafts: {},
  timeFrameAll: false,
  timeframeDays: { option: '', days: -1 },
  timeframeHours: { option: '', hours: -1 },
  timeframeCycles: { option: '', cycles: -1 },
  timeframeLandings: { option: '', landings: -1 },
  filterDataBy: [],
  projectionFilterData: initialProjectionFilterData,
  generalFilterData: initialGeneralFilterData,
  ataCodes: [],
  trackedBy: [],
}
export interface DueListSearchParams {
  activeTimeframeFilter?: string
  fuzzySearchTerms?: any
  powerSearchTerms?: any
  timeFrameAll?: boolean
  filterDataBy?: string
  aircrafts?: string
  option?: string
  days?: number
  landings?: number
  cycles?: number
  hours?: number
  ataCodes?: string
}

export function getDuelistSearchParams(
  filtersData: DueListTableFilterData
): DueListSearchParams {
  const {
    filterDataBy,
    fuzzySearchTerms,
    powerSearchTerms,
    aircrafts: aircraftFilter,
    timeFrameAll,
    timeframeCycles,
    timeframeLandings,
    timeframeDays,
    timeframeHours,
    ataCodes,
    trackedBy,
  } = filtersData
  const selectedAircrafts = Object.keys(aircraftFilter).filter(
    (acId) => aircraftFilter[acId]
  )
  let activeTimeframeFilter = ''
  if (timeframeDays.days > 0) {
    activeTimeframeFilter = 'days'
  } else if (timeframeHours.hours > 0) {
    activeTimeframeFilter = 'hours'
  } else if (timeframeCycles.cycles > 0) {
    activeTimeframeFilter = 'cycles'
  } else if (timeframeLandings.landings > 0) {
    activeTimeframeFilter = 'landings'
  }
  const timeframe = {
    ...(timeframeDays.days > 0 && timeframeDays),
    ...(timeframeHours.hours > 0 && timeframeHours),
    ...(timeframeCycles.cycles > 0 && timeframeCycles),
    ...(timeframeLandings.landings > 0 && timeframeLandings),
  }

  const getPowerSearchUrlParams = () => {
    const searchTermsKeys = Object.keys(powerSearchTerms)
    return searchTermsKeys.reduce((acc, key) => {
      const searchTerm = powerSearchTerms[key]
      if (searchTerm) {
        return {
          ...acc,
          [`powerSearch${key}`]: searchTerm,
        }
      }
      return acc
    }, {})
  }

  return {
    ...timeframe,
    ...(activeTimeframeFilter && { activeTimeframeFilter }),
    ...(fuzzySearchTerms && { fuzzySearchTerms: fuzzySearchTerms }),
    ...(Object.keys(powerSearchTerms).length > 0 && {
      ...getPowerSearchUrlParams(),
    }),
    ...(timeFrameAll && { timeFrameAll }),
    ...(filterDataBy.length > 0 && {
      filterDataBy: filterDataBy.join(','),
    }),
    ...(selectedAircrafts.length > 0 && {
      aircrafts: selectedAircrafts.join(','),
    }),
    ...(ataCodes?.length > 0 && { ataCodes: ataCodes.join(',') }),
    ...(trackedBy?.length > 0 && { trackedBy: trackedBy.join(',') }),
  }
}

export function areDuelistPrevDepValuesReset(
  prevDeps: DueListPrevDeps
): boolean {
  const {
    powerSearchTerms: prevPowerSearchTerm,
    fuzzySearchTerms: prevFuzzySearchTerm,
    aircrafts: prevAircrafts,
    timeFrameAll: prevTimeFrameAll,
    timeframeDays: prevTimeframeDays,
    timeframeHours: prevTimeframeHours,
    timeframeCycles: prevTimeframeCycles,
    timeframeLandings: prevTimeframeLandings,
    filterDataBy: prevFilterDataBy,
    ataCodes: prevAtaCodes,
    trackedBy: prevTrackedBy,
  } = prevDeps

  return (
    isNotEmpty(prevFuzzySearchTerm) ||
    isNotEmpty(prevPowerSearchTerm) ||
    isNotEmpty(prevAircrafts) ||
    isNotEmpty(prevTimeFrameAll) ||
    isNotEmpty(prevTimeframeDays?.option) ||
    isNotEmpty(prevTimeframeHours?.option) ||
    isNotEmpty(prevTimeframeCycles?.option) ||
    isNotEmpty(prevTimeframeLandings?.option) ||
    isNotEmpty(prevAtaCodes) ||
    isNotEmpty(prevTrackedBy) ||
    prevFilterDataBy.length !== 0
  )
}

/**
 * Given an object with filter data, return a new object which will be used to check if the filter data has changed.
 * @param filtersData
 * @returns
 */
export function getDueListDepsState(
  filtersData: DueListTableFilterData
): DueListPrevDeps {
  return {
    fuzzySearchTerms: filtersData.fuzzySearchTerms,
    powerSearchTerms: filtersData.powerSearchTerms,
    aircrafts: filtersData.aircrafts,
    timeFrameAll: filtersData.timeFrameAll,
    timeframeDays: filtersData.timeframeDays,
    timeframeHours: filtersData.timeframeHours,
    timeframeCycles: filtersData.timeframeCycles,
    timeframeLandings: filtersData.timeframeLandings,
    filterDataBy: filtersData.filterDataBy,
    projectionFilterData: filtersData.projectionFilterData,
    generalFilterData: filtersData.generalFilterData,
    ataCodes: filtersData.ataCodes,
    trackedBy: filtersData.trackedBy,
  }
}

/**
 * Populate the filter object from url search params, using initialFilterData as a base
 * @param searchParams
 * @returns
 */
export function getDueListFiltersFromUrl(
  searchParams: URLSearchParams
): DueListTableFilterData {
  const getParam = (key) => searchParams.get(key)
  const getParamArray = (key) => getParam(key)?.split(',') || []
  const getParamBool = (key) => getParam(key) === 'true'
  const getParamNumber = (key) => Number(getParam(key)) || -1

  const getPowerSearchTerms = () => {
    const powerSearchTermKeys = []
    for (const key of searchParams.keys()) {
      if (key.startsWith('powerSearch')) {
        powerSearchTermKeys.push(key)
      }
    }

    if (!powerSearchTermKeys.length) return null
    return powerSearchTermKeys.reduce((acc, key) => {
      const searchTerm = searchParams.get(key)
      const searchTermKey = key.replace('powerSearch', '')
      return {
        ...acc,
        [searchTermKey]: searchTerm,
      }
    }, {})
  }

  const filterDataBy = getParam('filterDataBy')
    ? getParamArray('filterDataBy')
    : initialFilterData.filterDataBy
  const powerSearchTerms =
    getPowerSearchTerms() || initialFilterData.powerSearchTerms
  const fuzzySearchTerms =
    getParam('fuzzySearchTerms') || initialFilterData.fuzzySearchTerms

  const aircrafts = getParam('aircrafts')
    ? Object.fromEntries(getParamArray('aircrafts').map((acId) => [acId, true]))
    : initialFilterData.aircrafts
  const timeFrameAll =
    getParamBool('timeFrameAll') || initialFilterData.timeFrameAll

  const activeTimeframeFilter = getParam('activeTimeframeFilter')
  const timeOption = getParam('option')
  const timeframeDays =
    activeTimeframeFilter === 'days'
      ? { days: getParamNumber('days'), option: timeOption }
      : initialFilterData.timeframeDays
  const timeframeHours =
    activeTimeframeFilter === 'hours'
      ? { hours: getParamNumber('hours'), option: timeOption }
      : initialFilterData.timeframeHours
  const timeframeCycles =
    activeTimeframeFilter === 'cycles'
      ? { cycles: getParamNumber('cycles'), option: timeOption }
      : initialFilterData.timeframeCycles
  const timeframeLandings =
    activeTimeframeFilter === 'landings'
      ? { landings: getParamNumber('landings'), option: timeOption }
      : initialFilterData.timeframeLandings

  const ataCodes = getParam('ataCodes')
    ? getParamArray('ataCodes')
    : initialFilterData.ataCodes

  const trackedBy = getParam('trackedBy')
    ? getParamArray('trackedBy')
    : initialFilterData.trackedBy

  // TODO: checks for invalid URLs to clear type errors
  return {
    ...initialFilterData,
    filterDataBy,
    powerSearchTerms,
    fuzzySearchTerms,
    aircrafts,
    timeFrameAll,
    timeframeDays,
    timeframeHours,
    timeframeCycles,
    timeframeLandings,
    projectionFilterData: initialProjectionFilterData,
    generalFilterData: initialGeneralFilterData,
    ataCodes,
    trackedBy,
  }
}

interface DuelistTableSliceState {
  filters: DueListTableFilterData
  showChildItems: boolean
  columnsToShow: string[]
  selectedItems: string[]
  selectedAircrafts: string[]
  selectedInProgressItems: SelectedInProgress
}

const initialState: DuelistTableSliceState = {
  filters: initialFilterData,
  showChildItems: false,
  columnsToShow: [],
  selectedItems: [],
  selectedAircrafts: [],
  selectedInProgressItems: {},
}

const dueListTableSlice = createSlice({
  name: 'dueListTable',
  initialState,
  reducers: {
    setDuelistFiltersData(
      state,
      action: PayloadAction<DueListTableFilterData>
    ): void {
      state.filters = action.payload
    },
    setDuelistColumnsData: (state, action: PayloadAction<string[]>) => {
      state.columnsToShow = action.payload
    },
    // TODO: make this action-based
    setShowChildItems: (state, action: PayloadAction<boolean>) => {
      state.showChildItems = action.payload
    },
    dueListItemSelected(
      state,
      action: PayloadAction<MaintenanceItem | MaintenanceItem[]>
    ) {
      const maintenanceItems = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]
      const ids = maintenanceItems.map((item) => item.id)
      const aircraftIds = maintenanceItems.map((item) => item.aircraftId)

      const uniqueIds = new Set([...state.selectedItems, ...ids])
      const uniqueAircraftIds = new Set([
        ...state.selectedAircrafts,
        ...aircraftIds,
      ])
      state.selectedItems = Array.from(uniqueIds)
      state.selectedAircrafts = Array.from(uniqueAircraftIds)
      maintenanceItems.forEach((item) => {
        if (isMaintenanceItemInProgress(item)) {
          state.selectedInProgressItems[item.id] = {
            ataCode: item.ataCode,
            manufactureCode: item.manufactureCode,
            title: item.title,
          }
        }
      })
    },
    dueListItemUnselected(
      state,
      action: PayloadAction<MaintenanceItem | MaintenanceItem[]>
    ) {
      const maintenanceItems = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]
      const idsToRemove = maintenanceItems.map((item) => item.id)
      const aircraftIdsToRemove = Array.from(
        new Set(maintenanceItems.map((item) => item.aircraftId))
      )

      state.selectedItems = state.selectedItems.filter(
        (id) => !idsToRemove.includes(id)
      )
      state.selectedAircrafts = state.selectedAircrafts.filter(
        (id) => !aircraftIdsToRemove.includes(id)
      )

      idsToRemove.forEach((id) => {
        delete state.selectedInProgressItems[id]
      })
    },
    unselectAllMaintenanceItems(state) {
      state.selectedItems = []
      state.selectedAircrafts = []
      state.selectedInProgressItems = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  },
})

export const {
  setDuelistFiltersData,
  setDuelistColumnsData,
  setShowChildItems,
  dueListItemSelected,
  dueListItemUnselected,
  unselectAllMaintenanceItems,
} = dueListTableSlice.actions

export default dueListTableSlice.reducer
