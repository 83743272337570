export const fakeData = {
  // maintenanceItems: [
  //   {
  //     "id": "99469dff-d631-4561-be08-7da7cc8afc3f",
  //     "ataCode": "05",
  //     "manufactureCode": "1201",
  //     "title": "PHASE 1 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "Test",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "cae901d2-c4ef-4a47-99ce-c48e45e4e286",
  //     "ataCode": "05",
  //     "manufactureCode": "1202",
  //     "title": "PHASE 2 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "b9381c0d-02c8-4ef6-ae2c-0a86e857eafc",
  //     "ataCode": "05",
  //     "manufactureCode": "1203",
  //     "title": "PHASE 3 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "0040be0d-8c5a-4855-a251-80ae681e2a80",
  //     "ataCode": "05",
  //     "manufactureCode": "1204",
  //     "title": "ENGINE MINOR INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "2722deab-68e7-471e-80b5-4c52c4eca471",
  //     "ataCode": "05",
  //     "manufactureCode": "1204",
  //     "title": "PHASE 4 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "2c412fa0-9b4e-4a60-8c01-5cd18f3b2460",
  //     "ataCode": "05",
  //     "manufactureCode": "1205",
  //     "title": "PHASE 5 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "Test",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 36,
  //       "tolerance": {},
  //       "flying_hours": 1200
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "d61b655c-daed-44ac-a160-c3f8fb194fe1",
  //     "ataCode": "05",
  //     "manufactureCode": "1210",
  //     "title": "PHASE 10 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 100
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "0187f029-93c2-435b-9eb2-78acf2886a22",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 PITOT STATIC LEAK CHECK",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "06cb084d-fa76-4bab-a480-2bef463efdbd",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 TRANSPONDER TEST",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "635bce69-fbc9-4c98-b271-1af4ed76c6d3",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 PITOT STATIC LEAK CHECK",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "7e18f1fe-91ed-4cfb-b65b-64247298ea11",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 AIRSPEED CALIBRATE",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "98970521-c4d1-4339-9cfc-424597c014a0",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 ALTIMETER CALIBRATE",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "0314e9b8-177a-4b97-b9cb-8f50d8658069",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 SKIN WAVINESS INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "11ebb5d9-b764-4a54-bb76-5f2bf44d791d",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "RVSM INSPECTION 1",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "1e0e708a-52e3-4c24-8196-83c303d43efd",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 AIR DATA DISPLAY UNIT TEST",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "5384c983-2399-4de7-b020-3996ecc88d26",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 AIR DATA DISPLAY UNIT TEST",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "7f7081a5-d067-4e07-aed9-a78b91759a67",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 AIRSPEED CALIBRATE",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "8858868e-ee1d-4741-8e28-da5e48689fa9",
  //     "ataCode": "05",
  //     "manufactureCode": "1220",
  //     "title": "PH 20 PITOT STATIC LEAK CHECK",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "f42332d1-08a4-4fa3-b903-a8ed74f571cb",
  //     "ataCode": "05",
  //     "manufactureCode": "1221",
  //     "title": "PHASE 21 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "356f6a3f-0d4b-4825-a0a1-bb621fad40c8",
  //     "ataCode": "05",
  //     "manufactureCode": "1225",
  //     "title": "HOT SECTION INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 1750
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "66bf1b37-3f22-43d4-be4d-f7ad02cc3239",
  //     "ataCode": "05",
  //     "manufactureCode": "1234",
  //     "title": "PH 34 PORTABLE FIRE EXT WEIGH",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 6,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "04cc7826-8ed8-414f-be12-11fae3689caf",
  //     "ataCode": "05",
  //     "manufactureCode": "1241",
  //     "title": "PHASE 41 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 1200
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "5dea0e40-044a-4414-b7c4-33e0e4b657bb",
  //     "ataCode": "05",
  //     "manufactureCode": "1249",
  //     "title": "PHASE 49 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 36,
  //       "tolerance": {},
  //       "flying_hours": 1200
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "f277052c-7e58-4d06-941a-2efbe91a9198",
  //     "ataCode": "05",
  //     "manufactureCode": "1253",
  //     "title": "PHASE 53 INSPECTION",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "Test",
  //     "cadenceType": "CALENDAR_MONTHS",
  //     "cadenceValue": {
  //       "months": 12,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "b8b84b6b-f17a-471c-84b3-fbc1e20f3110",
  //     "ataCode": "05",
  //     "manufactureCode": "5510",
  //     "title": "VERTICAL STABILIZER ULTRASONIC",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 18,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "07a61054-7cde-4c76-9c9c-f01695d9c825",
  //     "ataCode": "05",
  //     "manufactureCode": "7405",
  //     "title": "LOWER IGNITION EXCITER VISUAL INSPECT",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "months": 24,
  //       "tolerance": {},
  //       "flying_hours": 300
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "e8ace9a1-62e7-4991-b9ec-a64da0c3513d",
  //       "isPrimary": false,
  //       "partNumber": "ST223344",
  //       "serialNumber": "342343",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "565848c0-3bc1-4d35-9a7d-b1f86a6af8a6",
  //     "ataCode": "05",
  //     "manufactureCode": "7930",
  //     "title": "OIL FILTER ELEMENT REPLACE",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 200
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "7cfe798f-2407-4f08-b8f8-9dd30a64e40a",
  //     "ataCode": "05",
  //     "manufactureCode": "7930",
  //     "title": "OIL FILTER ELEMENT REPLACE",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "INSPECTION",
  //     "disposition": "",
  //     "notes": "Test",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 200
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": null,
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "143f8064-951c-4654-82d1-b85f51215058",
  //     "ataCode": "21",
  //     "manufactureCode": "2002",
  //     "title": "OVERHEAD BLOWER",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 1000
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "7ea2cb67-f9ac-41fd-ace0-e10738966a53",
  //       "isPrimary": false,
  //       "partNumber": "BC61A-901-2",
  //       "serialNumber": "123A",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "ddac055c-4890-453a-b6ff-600b2a5e7e89",
  //     "ataCode": "24",
  //     "manufactureCode": "3001",
  //     "title": "STARTER GENERATOR",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 1000
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "c98067bf-4185-4447-893c-eae3ad1d69f5",
  //       "isPrimary": false,
  //       "partNumber": "23076-001-1",
  //       "serialNumber": "2009",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "6d046ea1-9ec8-45e4-bc89-392174e7c3d5",
  //     "ataCode": "71",
  //     "manufactureCode": "2001",
  //     "title": "AFT ENGINE ISOLATOR MOUNT",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 3500
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "70cbb666-f73a-4b20-ae9c-8ba14bcee189",
  //       "isPrimary": false,
  //       "partNumber": "LM420SA6-1",
  //       "serialNumber": "1747",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "b5d24c04-682a-4aab-9e2f-fe823cce433a",
  //     "ataCode": "71",
  //     "manufactureCode": "2001",
  //     "title": "AFT ENGINE ISOLATOR MOUNT",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 3500
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "85603537-a4d4-4a72-9a0d-3efcc45f4557",
  //       "isPrimary": false,
  //       "partNumber": "LM420SA6-1",
  //       "serialNumber": "2766",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "2b6f2143-e7b6-40d0-ab30-34702e709ae1",
  //     "ataCode": "71",
  //     "manufactureCode": "2002",
  //     "title": "FORWARD ENGINE ISOLATOR MOUNT",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 3500
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "a18591c8-178d-4bfc-8b3f-5ecc28188934",
  //       "isPrimary": false,
  //       "partNumber": "LM420SA5-1",
  //       "serialNumber": "4043",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "df0fc4da-ddc6-4a9f-be72-73c2b44eb61f",
  //     "ataCode": "71",
  //     "manufactureCode": "2002",
  //     "title": "FORWARD ENGINE ISOLATOR MOUNT",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "FLYING_HOURS",
  //     "cadenceValue": {
  //       "tolerance": {},
  //       "flying_hours": 3500
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "6b15f654-be8d-4e12-b48b-bddef7ea6a1f",
  //       "isPrimary": false,
  //       "partNumber": "LM420SA5-1",
  //       "serialNumber": "4040",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   },
  //   {
  //     "id": "9539b33c-b448-4555-954e-6e2e449cbaf6",
  //     "ataCode": "72",
  //     "manufactureCode": "3010",
  //     "title": "CENTRIFUGAL IMPELLER",
  //     "trackedBy": "AIRFRAME",
  //     "trackedByComponentId": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //     "maintenanceType": "PART",
  //     "disposition": "",
  //     "notes": "",
  //     "cadenceType": "LANDINGS",
  //     "cadenceValue": {
  //       "landings": 8400,
  //       "tolerance": {}
  //     },
  //     "__typename": "MaintenanceItem",
  //     "aircraftComponent": {
  //       "id": "3dcf411e-d288-4b2c-86ba-77b2bd3a353e",
  //       "isPrimary": false,
  //       "partNumber": "3020365",
  //       "serialNumber": "PB1208",
  //       "monthsSinceNew": -1,
  //       "monthsSinceOverhaul": -1,
  //       "monthsSinceRepair": -1,
  //       "hoursSinceNew": -1,
  //       "hoursSinceOverhaul": -1,
  //       "hoursSinceRepair": -1,
  //       "cyclesSinceNew": -1,
  //       "cyclesSinceOverhaul": -1,
  //       "cyclesSinceRepair": -1,
  //       "__typename": "AircraftComponent"
  //     },
  //     "aircraft": {
  //       "id": "cab42d62-1e83-4cfe-bc22-277b5bceee06",
  //       "__typename": "Aircraft"
  //     },
  //     "childItems": [],
  //     "trackedByComponent": {
  //       "id": "7850e4f7-4d6c-493b-a3c9-4ddb612daf94",
  //       "name": "Airframe",
  //       "__typename": "AircraftComponent"
  //     }
  //   }
  // ]

  maintenanceItems: [
    {
      id: 'd61b655c-daed-44ac-a160-c3f8fb194fe1',
      ataCode: '05',
      manufactureCode: '1210',
      title: 'PHASE 10 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 100,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-04-28T05:00:00.000Z',
      calculatedNextDueAt: '2024-08-22T18:00:22.095Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2024-02-05T00:00:00.000Z',
        logHours: 12176,
        logLandings: 11928,
      },
      lastComplianceStampId: 'a726537c-15d4-458c-ab2d-eb6733b370c8',
      nextDueStatus: 'OVERDUE',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '1a9e8058-6070-4eca-a6a3-d89596dd8fbc',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12359,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: 'a726537c-15d4-458c-ab2d-eb6733b370c8',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-04-28T05:00:00.000Z',
        airframeTotalTime: 12259,
        airframeCycle: 11993,
        createdAt: '2024-04-28T21:05:19.975Z',
        updatedAt: '2024-04-28T21:05:19.975Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '3a1b0c8f-2274-4227-af98-ba560c4dae20',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-04-28T05:00:00.000Z',
            totalTimeSinceNew: 12259,
            timeSinceOverhaul: 12259,
            cycleSinceNew: 11993,
            cycleSinceOverhaul: 11993,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: 'bc1390c9-724d-488e-ab67-ed2c062690ca',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-04-28T05:00:00.000Z',
            totalTimeSinceNew: 8371.1,
            timeSinceOverhaul: 8371.1,
            cycleSinceNew: 6614,
            cycleSinceOverhaul: 6614,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: 'f79eed82-df52-47a2-a8b1-ef2aa1e9a047',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-04-28T05:00:00.000Z',
            totalTimeSinceNew: 4489,
            timeSinceOverhaul: 4489,
            cycleSinceNew: 5025,
            cycleSinceOverhaul: 5025,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: 'fdd39436-1793-410f-84d3-151211bd618e',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-04-27T05:00:00.000Z',
            totalTimeSinceNew: 1524.8,
            timeSinceOverhaul: 1524.8,
            cycleSinceNew: 0,
            cycleSinceOverhaul: 0,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '0040be0d-8c5a-4855-a251-80ae681e2a80',
      ataCode: '05',
      manufactureCode: '1204',
      title: 'ENGINE MINOR INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-11-08T00:00:00.000Z',
      calculatedNextDueAt: '2025-11-30T00:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-11-08T00:00:00.000Z',
        logHours: 12031,
        logLandings: 11842,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'OVERDUE',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '19ada9af-b26a-4832-a831-558f9ccb25b8',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2025-11-30T00:00:00.000Z',
            flying_hours: 12330,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '2722deab-68e7-471e-80b5-4c52c4eca471',
      ataCode: '05',
      manufactureCode: '1204',
      title: 'PHASE 4 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-11-08T00:00:00.000Z',
      calculatedNextDueAt: '2025-11-30T00:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-11-08T00:00:00.000Z',
        logHours: 12031,
        logLandings: 11842,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'OVERDUE',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '903bc405-a7e4-4ccb-9e84-5da49a832529',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2025-11-30T00:00:00.000Z',
            flying_hours: 12330,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '66bf1b37-3f22-43d4-be4d-f7ad02cc3239',
      ataCode: '05',
      manufactureCode: '1234',
      title: 'PH 34 PORTABLE FIRE EXT WEIGH',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 6,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2025-03-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2024-02-05T00:00:00.000Z',
        logHours: 12176,
        logLandings: 11928,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'fe21e1b4-638d-4d62-a8f2-970f92126668',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2025-03-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '7cfe798f-2407-4f08-b8f8-9dd30a64e40a',
      ataCode: '05',
      manufactureCode: '7930',
      title: 'OIL FILTER ELEMENT REPLACE',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 200,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2025-05-07T18:00:21.817Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2024-02-29T00:00:00.000Z',
        logHours: 12184,
        logLandings: 11937,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '026f41b6-6e35-41d4-851c-5955852a1d26',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12617,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '565848c0-3bc1-4d35-9a7d-b1f86a6af8a6',
      ataCode: '05',
      manufactureCode: '7930',
      title: 'OIL FILTER ELEMENT REPLACE',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 200,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2025-05-07T18:00:22.078Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2024-02-29T00:00:00.000Z',
        logHours: 12184,
        logLandings: 11937,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'a2ad9a39-138f-41f2-8284-9ded550aa187',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12617,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'f277052c-7e58-4d06-941a-2efbe91a9198',
      ataCode: '05',
      manufactureCode: '1253',
      title: 'PHASE 53 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 12,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-18T04:00:00.000Z',
      calculatedNextDueAt: '2025-06-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '7b563d9f-87ae-4322-a471-7d348114d45d',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '86a27a78-1d5d-47c8-bbb6-0b5e609ac8cf',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2025-06-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '7b563d9f-87ae-4322-a471-7d348114d45d',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-18T04:00:00.000Z',
        airframeTotalTime: 12317,
        airframeCycle: 12013,
        createdAt: '2024-06-18T18:14:16.046Z',
        updatedAt: '2024-06-18T18:14:16.046Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: 'd7b8c3a4-5a47-4a20-a065-20969371359d',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 12317,
            timeSinceOverhaul: 12317,
            cycleSinceNew: 12013,
            cycleSinceOverhaul: 12013,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '672aec3a-c78a-49e8-9e4a-6d42d838a033',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 8429.1,
            timeSinceOverhaul: 8429.1,
            cycleSinceNew: 6634,
            cycleSinceOverhaul: 6634,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '267d1daf-0b56-403b-a33b-50c52f1be1a9',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 4547,
            timeSinceOverhaul: 4547,
            cycleSinceNew: 5045,
            cycleSinceOverhaul: 5045,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '6d046ea1-9ec8-45e4-bc89-392174e7c3d5',
      ataCode: '71',
      manufactureCode: '2001',
      title: 'AFT ENGINE ISOLATOR MOUNT',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 3500,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'PART',
      lastComplianceDate: '2013-12-03T00:00:00.000Z',
      calculatedNextDueAt: '2025-09-11T18:00:21.893Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2013-12-03T00:00:00.000Z',
        logHours: 9244,
        logLandings: 9767,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: {
        partNumber: 'LM420SA6-1',
        serialNumber: '1747',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: '1b3b7c8b-c618-41b8-b190-4b6e9cd652a1',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 12744,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '06cb084d-fa76-4bab-a480-2bef463efdbd',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 TRANSPONDER TEST',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-09-11T00:00:00.000Z',
      calculatedNextDueAt: '2025-09-30T00:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '66022a54-a409-4133-bb0f-b0c5133c2c54',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2025-09-30T00:00:00.000Z',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '635bce69-fbc9-4c98-b271-1af4ed76c6d3',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 PITOT STATIC LEAK CHECK',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-09-11T00:00:00.000Z',
      calculatedNextDueAt: '2025-09-30T00:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '518ec9a1-b180-4c1c-b5c9-3682184c1086',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2025-09-30T00:00:00.000Z',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '7e18f1fe-91ed-4cfb-b65b-64247298ea11',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 AIRSPEED CALIBRATE',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-09-11T00:00:00.000Z',
      calculatedNextDueAt: '2025-09-30T00:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'd5562cc4-d635-424c-972a-613b31698324',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2025-09-30T00:00:00.000Z',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '98970521-c4d1-4339-9cfc-424597c014a0',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 ALTIMETER CALIBRATE',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-09-11T00:00:00.000Z',
      calculatedNextDueAt: '2025-09-30T00:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '466ed063-0255-49e7-877e-36cb1750fff8',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2025-09-30T00:00:00.000Z',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '143f8064-951c-4654-82d1-b85f51215058',
      ataCode: '21',
      manufactureCode: '2002',
      title: 'OVERHEAD BLOWER',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 1000,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'PART',
      lastComplianceDate: '2023-06-05T00:00:00.000Z',
      calculatedNextDueAt: '2025-11-29T18:00:22.096Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-06-05T00:00:00.000Z',
        logHours: 11823,
        logLandings: 11672,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: {
        partNumber: 'BC61A-901-2',
        serialNumber: '123A',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: 'a807fce0-ed9f-48d2-869a-310d806bfa02',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 12823,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'b8b84b6b-f17a-471c-84b3-fbc1e20f3110',
      ataCode: '05',
      manufactureCode: '5510',
      title: 'VERTICAL STABILIZER ULTRASONIC',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 18,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-18T04:00:00.000Z',
      calculatedNextDueAt: '2025-12-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-11-16T00:00:00.000Z',
        logHours: 12039,
        logLandings: 11847,
      },
      lastComplianceStampId: '7b563d9f-87ae-4322-a471-7d348114d45d',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '196745ab-8faf-4b57-9843-7968fc348b5a',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2025-12-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12617,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '7b563d9f-87ae-4322-a471-7d348114d45d',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-18T04:00:00.000Z',
        airframeTotalTime: 12317,
        airframeCycle: 12013,
        createdAt: '2024-06-18T18:14:16.046Z',
        updatedAt: '2024-06-18T18:14:16.046Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: 'd7b8c3a4-5a47-4a20-a065-20969371359d',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 12317,
            timeSinceOverhaul: 12317,
            cycleSinceNew: 12013,
            cycleSinceOverhaul: 12013,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '672aec3a-c78a-49e8-9e4a-6d42d838a033',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 8429.1,
            timeSinceOverhaul: 8429.1,
            cycleSinceNew: 6634,
            cycleSinceOverhaul: 6634,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '267d1daf-0b56-403b-a33b-50c52f1be1a9',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 4547,
            timeSinceOverhaul: 4547,
            cycleSinceNew: 5045,
            cycleSinceOverhaul: 5045,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '9539b33c-b448-4555-954e-6e2e449cbaf6',
      ataCode: '72',
      manufactureCode: '3010',
      title: 'CENTRIFUGAL IMPELLER',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        landings: 8400,
        tolerance: {},
      },
      cadenceType: 'LANDINGS',
      maintenanceType: 'PART',
      lastComplianceDate: '2023-09-20T00:00:00.000Z',
      calculatedNextDueAt: '2026-03-13T18:00:21.897Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-20T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11778,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: {
        partNumber: '3020365',
        serialNumber: 'PB1208',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: 'f8994409-09ea-424b-a90b-3c93b77c7959',
          nextDueType: 'LANDINGS',
          nextDueValue: {
            landings: 12572,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'cae901d2-c4ef-4a47-99ce-c48e45e4e286',
      ataCode: '05',
      manufactureCode: '1202',
      title: 'PHASE 2 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-17T04:00:00.000Z',
      calculatedNextDueAt: '2026-06-17T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-11-08T00:00:00.000Z',
        logHours: 12031,
        logLandings: 11842,
      },
      lastComplianceStampId: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '922c9d79-9fe9-48d9-889c-003e88c8fa26',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2026-06-17T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12615,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-17T04:00:00.000Z',
        airframeTotalTime: 12315,
        airframeCycle: 12012,
        createdAt: '2024-06-18T00:29:56.104Z',
        updatedAt: '2024-06-18T00:29:56.104Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '77ded2cf-c6fe-4d13-88fc-201ce5c66dd0',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12315,
            timeSinceOverhaul: 12315,
            cycleSinceNew: 12012,
            cycleSinceOverhaul: 12012,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '981a52d7-68ab-40c7-962c-7adb033e1d12',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 8427.1,
            timeSinceOverhaul: 8427.1,
            cycleSinceNew: 6633,
            cycleSinceOverhaul: 6633,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '7e3850f2-bcdf-4137-820e-4013af3da778',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 4545,
            timeSinceOverhaul: 4545,
            cycleSinceNew: 5044,
            cycleSinceOverhaul: 5044,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'b9381c0d-02c8-4ef6-ae2c-0a86e857eafc',
      ataCode: '05',
      manufactureCode: '1203',
      title: 'PHASE 3 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-17T04:00:00.000Z',
      calculatedNextDueAt: '2026-06-17T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-11-08T00:00:00.000Z',
        logHours: 12031,
        logLandings: 11842,
      },
      lastComplianceStampId: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '55d3ca17-6607-4a91-873e-b220a0829c2f',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2026-06-17T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12615,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-17T04:00:00.000Z',
        airframeTotalTime: 12315,
        airframeCycle: 12012,
        createdAt: '2024-06-18T00:29:56.104Z',
        updatedAt: '2024-06-18T00:29:56.104Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '77ded2cf-c6fe-4d13-88fc-201ce5c66dd0',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12315,
            timeSinceOverhaul: 12315,
            cycleSinceNew: 12012,
            cycleSinceOverhaul: 12012,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '981a52d7-68ab-40c7-962c-7adb033e1d12',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 8427.1,
            timeSinceOverhaul: 8427.1,
            cycleSinceNew: 6633,
            cycleSinceOverhaul: 6633,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '7e3850f2-bcdf-4137-820e-4013af3da778',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 4545,
            timeSinceOverhaul: 4545,
            cycleSinceNew: 5044,
            cycleSinceOverhaul: 5044,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '07a61054-7cde-4c76-9c9c-f01695d9c825',
      ataCode: '05',
      manufactureCode: '7405',
      title: 'LOWER IGNITION EXCITER VISUAL INSPECT',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-17T04:00:00.000Z',
      calculatedNextDueAt: '2026-06-17T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2024-02-29T00:00:00.000Z',
        logHours: 12184,
        logLandings: 11937,
      },
      lastComplianceStampId: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: {
        partNumber: 'ST223344',
        serialNumber: '342343',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: '44eb4383-606c-4d9d-9a8a-4b85421cacdf',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2026-06-17T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12615,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-17T04:00:00.000Z',
        airframeTotalTime: 12315,
        airframeCycle: 12012,
        createdAt: '2024-06-18T00:29:56.104Z',
        updatedAt: '2024-06-18T00:29:56.104Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '77ded2cf-c6fe-4d13-88fc-201ce5c66dd0',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12315,
            timeSinceOverhaul: 12315,
            cycleSinceNew: 12012,
            cycleSinceOverhaul: 12012,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '981a52d7-68ab-40c7-962c-7adb033e1d12',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 8427.1,
            timeSinceOverhaul: 8427.1,
            cycleSinceNew: 6633,
            cycleSinceOverhaul: 6633,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '7e3850f2-bcdf-4137-820e-4013af3da778',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 4545,
            timeSinceOverhaul: 4545,
            cycleSinceNew: 5044,
            cycleSinceOverhaul: 5044,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '99469dff-d631-4561-be08-7da7cc8afc3f',
      ataCode: '05',
      manufactureCode: '1201',
      title: 'PHASE 1 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
        flying_hours: 300,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-06-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-11-08T00:00:00.000Z',
        logHours: 12031,
        logLandings: 11842,
      },
      lastComplianceStampId: '7b563d9f-87ae-4322-a471-7d348114d45d',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '31adcf98-c575-41d9-ad53-895379ef4444',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2026-06-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 12617,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '7b563d9f-87ae-4322-a471-7d348114d45d',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-18T04:00:00.000Z',
        airframeTotalTime: 12317,
        airframeCycle: 12013,
        createdAt: '2024-06-18T18:14:16.046Z',
        updatedAt: '2024-06-18T18:14:16.046Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: 'd7b8c3a4-5a47-4a20-a065-20969371359d',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 12317,
            timeSinceOverhaul: 12317,
            cycleSinceNew: 12013,
            cycleSinceOverhaul: 12013,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '672aec3a-c78a-49e8-9e4a-6d42d838a033',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 8429.1,
            timeSinceOverhaul: 8429.1,
            cycleSinceNew: 6634,
            cycleSinceOverhaul: 6634,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '267d1daf-0b56-403b-a33b-50c52f1be1a9',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 4547,
            timeSinceOverhaul: 4547,
            cycleSinceNew: 5045,
            cycleSinceOverhaul: 5045,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '0187f029-93c2-435b-9eb2-78acf2886a22',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 PITOT STATIC LEAK CHECK',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-06-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '7b563d9f-87ae-4322-a471-7d348114d45d',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '105f9a94-c951-4e00-bdd0-8994f52988b8',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-06-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '7b563d9f-87ae-4322-a471-7d348114d45d',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-18T04:00:00.000Z',
        airframeTotalTime: 12317,
        airframeCycle: 12013,
        createdAt: '2024-06-18T18:14:16.046Z',
        updatedAt: '2024-06-18T18:14:16.046Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: 'd7b8c3a4-5a47-4a20-a065-20969371359d',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 12317,
            timeSinceOverhaul: 12317,
            cycleSinceNew: 12013,
            cycleSinceOverhaul: 12013,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '672aec3a-c78a-49e8-9e4a-6d42d838a033',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 8429.1,
            timeSinceOverhaul: 8429.1,
            cycleSinceNew: 6634,
            cycleSinceOverhaul: 6634,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '267d1daf-0b56-403b-a33b-50c52f1be1a9',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 4547,
            timeSinceOverhaul: 4547,
            cycleSinceNew: 5045,
            cycleSinceOverhaul: 5045,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '0314e9b8-177a-4b97-b9cb-8f50d8658069',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 SKIN WAVINESS INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2022-08-02T00:00:00.000Z',
        logHours: 11213,
        logLandings: 11273,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '288e4e49-a25b-4870-a213-2bf90c75f9a0',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '11ebb5d9-b764-4a54-bb76-5f2bf44d791d',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'RVSM INSPECTION 1',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'f491197b-738f-4e2c-92f9-10d8baaf1935',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '1e0e708a-52e3-4c24-8196-83c303d43efd',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 AIR DATA DISPLAY UNIT TEST',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'a85dbc4a-f086-41f2-9cc6-a60c29411ee6',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '5384c983-2399-4de7-b020-3996ecc88d26',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 AIR DATA DISPLAY UNIT TEST',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '7d68ebf8-6c62-493c-840b-5affd9cec92f',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '7f7081a5-d067-4e07-aed9-a78b91759a67',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 AIRSPEED CALIBRATE',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '80ee685f-b6e9-4110-a095-769eb5a9fb31',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '8858868e-ee1d-4741-8e28-da5e48689fa9',
      ataCode: '05',
      manufactureCode: '1220',
      title: 'PH 20 PITOT STATIC LEAK CHECK',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-09-11T00:00:00.000Z',
        logHours: 11961,
        logLandings: 11777,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'ebfa8250-d997-4c1a-bb27-8c73d456220f',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'f42332d1-08a4-4fa3-b903-a8ed74f571cb',
      ataCode: '05',
      manufactureCode: '1221',
      title: 'PHASE 21 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 24,
        tolerance: {},
      },
      cadenceType: 'CALENDAR_MONTHS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-09-18T04:00:00.000Z',
      calculatedNextDueAt: '2026-09-18T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-02-09T00:00:00.000Z',
        logHours: 11585,
        logLandings: 11506,
      },
      lastComplianceStampId: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '10edd5d4-bce0-46a0-a908-f838b8f861a2',
          nextDueType: 'CALENDAR_MONTHS',
          nextDueValue: {
            date: '2026-09-18T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '6e66c0f7-5a0c-408c-8d49-6d6b9e9f89f7',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-18T04:00:00.000Z',
        airframeTotalTime: 12417,
        airframeCycle: 12063,
        createdAt: '2024-09-18T17:41:09.819Z',
        updatedAt: '2024-09-18T17:41:09.819Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '6f96e96d-8e14-4115-8d93-30fff3bf35ad',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 12417,
            timeSinceOverhaul: 12417,
            cycleSinceNew: 12063,
            cycleSinceOverhaul: 12063,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '46c7ce49-7b37-4d8d-9aeb-71dbea77ecea',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 8529.1,
            timeSinceOverhaul: 8529.1,
            cycleSinceNew: 6684,
            cycleSinceOverhaul: 6684,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '121dc4ba-e000-40ca-80aa-a5ff30f65fe5',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-18T04:00:00.000Z',
            totalTimeSinceNew: 4647,
            timeSinceOverhaul: 4647,
            cycleSinceNew: 5095,
            cycleSinceOverhaul: 5095,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '356f6a3f-0d4b-4825-a0a1-bb621fad40c8',
      ataCode: '05',
      manufactureCode: '1225',
      title: 'HOT SECTION INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 1750,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2023-01-20T00:00:00.000Z',
      calculatedNextDueAt: '2026-10-25T18:00:21.856Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2023-01-20T00:00:00.000Z',
        logHours: 11567,
        logLandings: 11493,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'bc992d42-7a18-4d77-bca6-17202bd6e1cf',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 13153,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'df0fc4da-ddc6-4a9f-be72-73c2b44eb61f',
      ataCode: '71',
      manufactureCode: '2002',
      title: 'FORWARD ENGINE ISOLATOR MOUNT',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 3500,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'PART',
      lastComplianceDate: '2017-05-10T00:00:00.000Z',
      calculatedNextDueAt: '2026-11-23T18:00:21.899Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2017-05-10T00:00:00.000Z',
        logHours: 9682,
        logLandings: 10161,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: {
        partNumber: 'LM420SA5-1',
        serialNumber: '4040',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: '2247501d-e929-46cd-9a0f-021afbf537a6',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 13182,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'b5d24c04-682a-4aab-9e2f-fe823cce433a',
      ataCode: '71',
      manufactureCode: '2001',
      title: 'AFT ENGINE ISOLATOR MOUNT',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 3500,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'PART',
      lastComplianceDate: '2017-05-10T00:00:00.000Z',
      calculatedNextDueAt: '2026-11-23T18:00:22.058Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2017-05-10T00:00:00.000Z',
        logHours: 9682,
        logLandings: 10161,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: {
        partNumber: 'LM420SA6-1',
        serialNumber: '2766',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: 'aca33df0-c3a8-4abf-ad25-b09e395c06e9',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 13182,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '2b6f2143-e7b6-40d0-ab30-34702e709ae1',
      ataCode: '71',
      manufactureCode: '2002',
      title: 'FORWARD ENGINE ISOLATOR MOUNT',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 3500,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'PART',
      lastComplianceDate: '2017-05-10T00:00:00.000Z',
      calculatedNextDueAt: '2026-11-23T18:00:22.096Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2017-05-10T00:00:00.000Z',
        logHours: 9682,
        logLandings: 10161,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: {
        partNumber: 'LM420SA5-1',
        serialNumber: '4043',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: '6eedf00c-5803-4cba-8057-274a0835a7ad',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 13182,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: 'ddac055c-4890-453a-b6ff-600b2a5e7e89',
      ataCode: '24',
      manufactureCode: '3001',
      title: 'STARTER GENERATOR',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 1000,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'PART',
      lastComplianceDate: '2024-06-18T04:00:00.000Z',
      calculatedNextDueAt: '2027-04-07T18:00:22.013Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2022-12-23T00:00:00.000Z',
        logHours: 11521,
        logLandings: 11458,
      },
      lastComplianceStampId: '7b563d9f-87ae-4322-a471-7d348114d45d',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: {
        partNumber: '23076-001-1',
        serialNumber: '2009',
        __typename: 'AircraftComponent',
      },
      maintenanceNextDue: [
        {
          id: '4bd2911a-41ea-4101-9ffb-a3135bcbbf66',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 13317,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '7b563d9f-87ae-4322-a471-7d348114d45d',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-18T04:00:00.000Z',
        airframeTotalTime: 12317,
        airframeCycle: 12013,
        createdAt: '2024-06-18T18:14:16.046Z',
        updatedAt: '2024-06-18T18:14:16.046Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: 'd7b8c3a4-5a47-4a20-a065-20969371359d',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 12317,
            timeSinceOverhaul: 12317,
            cycleSinceNew: 12013,
            cycleSinceOverhaul: 12013,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '672aec3a-c78a-49e8-9e4a-6d42d838a033',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 8429.1,
            timeSinceOverhaul: 8429.1,
            cycleSinceNew: 6634,
            cycleSinceOverhaul: 6634,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '267d1daf-0b56-403b-a33b-50c52f1be1a9',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-18T04:00:00.000Z',
            totalTimeSinceNew: 4547,
            timeSinceOverhaul: 4547,
            cycleSinceNew: 5045,
            cycleSinceOverhaul: 5045,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '5dea0e40-044a-4414-b7c4-33e0e4b657bb',
      ataCode: '05',
      manufactureCode: '1249',
      title: 'PHASE 49 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 36,
        tolerance: {},
        flying_hours: 1200,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-04-27T05:00:00.000Z',
      calculatedNextDueAt: '2027-04-27T05:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2022-08-19T00:00:00.000Z',
        logHours: 11248,
        logLandings: 11293,
      },
      lastComplianceStampId: 'fca1a82d-4490-4b83-af02-794a600243c5',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: '25097d62-1ffd-477a-91f1-0530a05e9437',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2027-04-27T05:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 13458.6,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: 'fca1a82d-4490-4b83-af02-794a600243c5',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-04-27T05:00:00.000Z',
        airframeTotalTime: 12258.6,
        airframeCycle: 11990,
        createdAt: '2024-04-27T16:04:49.725Z',
        updatedAt: '2024-04-27T16:04:49.725Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '8145694f-945c-4117-87ac-9269e9fbb77a',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-04-27T05:00:00.000Z',
            totalTimeSinceNew: 12258.6,
            timeSinceOverhaul: 12258.6,
            cycleSinceNew: 11990,
            cycleSinceOverhaul: 11990,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '32086bd8-04fa-4d99-b438-56a8cc00a6b5',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-04-27T05:00:00.000Z',
            totalTimeSinceNew: 8370.7,
            timeSinceOverhaul: 8370.7,
            cycleSinceNew: 6611,
            cycleSinceOverhaul: 6611,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '346a2155-3b43-422e-8238-8faea43d4d52',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-04-27T05:00:00.000Z',
            totalTimeSinceNew: 4488.6,
            timeSinceOverhaul: 4488.6,
            cycleSinceNew: 5022,
            cycleSinceOverhaul: 5022,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: 'fdd39436-1793-410f-84d3-151211bd618e',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-04-27T05:00:00.000Z',
            totalTimeSinceNew: 1524.8,
            timeSinceOverhaul: 1524.8,
            cycleSinceNew: 0,
            cycleSinceOverhaul: 0,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '04cc7826-8ed8-414f-be12-11fae3689caf',
      ataCode: '05',
      manufactureCode: '1241',
      title: 'PHASE 41 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        tolerance: {},
        flying_hours: 1200,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-01-09T00:00:00.000Z',
      calculatedNextDueAt: '2027-05-03T18:00:21.935Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2024-01-09T00:00:00.000Z',
        logHours: 12143,
        logLandings: 11910,
      },
      lastComplianceStampId: null,
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      lastComplianceStamp: null,
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'af62de54-9f4a-4e5f-8447-70a2ba32070c',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            flying_hours: 13343,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
    {
      id: '2c412fa0-9b4e-4a60-8c01-5cd18f3b2460',
      ataCode: '05',
      manufactureCode: '1205',
      title: 'PHASE 5 INSPECTION',
      aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
      cadenceValue: {
        months: 36,
        tolerance: {},
        flying_hours: 1200,
      },
      cadenceType: 'FLYING_HOURS',
      maintenanceType: 'INSPECTION',
      lastComplianceDate: '2024-06-17T04:00:00.000Z',
      calculatedNextDueAt: '2027-06-17T04:00:00.000Z',
      trackedBy: 'AIRFRAME',
      trackedByComponentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
      importedDataCompliance: {
        logDate: '2022-08-02T00:00:00.000Z',
        logHours: 11213,
        logLandings: 11273,
      },
      lastComplianceStampId: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
      nextDueStatus: 'GOOD',
      __typename: 'MaintenanceItem',
      aircraftComponent: null,
      maintenanceNextDue: [
        {
          id: 'd6ea6741-6c33-41f7-a714-c9c36e23c001',
          nextDueType: 'FLYING_HOURS',
          nextDueValue: {
            date: '2027-06-17T04:00:00.000Z',
            note: 'Next due calculated based on the current compliance information',
            flying_hours: 13515,
          },
          isCompleted: false,
          nextDueOverrideType: null,
          nextDueOverride: null,
          __typename: 'MaintenanceNextDue',
          nextDueOverrideUser: null,
        },
      ],
      trackedByComponent: {
        id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
        name: 'Airframe',
        __typename: 'AircraftComponent',
        ComponentUsageLog: [
          {
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
      lastComplianceStamp: {
        id: '2a9d4971-7c6c-470c-a61d-fb21176574c8',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-06-17T04:00:00.000Z',
        airframeTotalTime: 12315,
        airframeCycle: 12012,
        createdAt: '2024-06-18T00:29:56.104Z',
        updatedAt: '2024-06-18T00:29:56.104Z',
        __typename: 'AircraftUsageLog',
        usageLoggedBy: {
          id: '8087b51e-f82e-42bf-99dd-f473b3633bfc',
          firstName: 'Matthew',
          lastName: 'McDermott',
          __typename: 'User',
        },
        ComponentUsageLog: [
          {
            id: '77ded2cf-c6fe-4d13-88fc-201ce5c66dd0',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12315,
            timeSinceOverhaul: 12315,
            cycleSinceNew: 12012,
            cycleSinceOverhaul: 12012,
            __typename: 'ComponentUsageLog',
            component: {
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '981a52d7-68ab-40c7-962c-7adb033e1d12',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 8427.1,
            timeSinceOverhaul: 8427.1,
            cycleSinceNew: 6633,
            cycleSinceOverhaul: 6633,
            __typename: 'ComponentUsageLog',
            component: {
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
              __typename: 'AircraftComponent',
            },
          },
          {
            id: '7e3850f2-bcdf-4137-820e-4013af3da778',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 4545,
            timeSinceOverhaul: 4545,
            cycleSinceNew: 5044,
            cycleSinceOverhaul: 5044,
            __typename: 'ComponentUsageLog',
            component: {
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
              __typename: 'AircraftComponent',
            },
          },
        ],
      },
    },
  ],
  aircraft: {
    __typename: 'Aircraft',
    id: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
    model: 'Cessna Citation I/SP',
    name: 'Cessna Citation I/SP',
    serialNumber: '501-0264',
    onboardStatus: 'ONBOARDED',
    orgSlug: 'org1',
    tailNumber: 'N1234',
    profilePicture: null,
    AircraftUsageLog: [
      {
        __typename: 'AircraftUsageLog',
        id: 'e8fb7413-35e9-401e-9493-8267e484c9c1',
        aircraftId: 'cab42d62-1e83-4cfe-bc22-277b5bceee06',
        usageAsOf: '2024-09-27T04:00:00.000Z',
        airframeTotalTime: 12419,
        airframeCycle: 12064,
        createdAt: '2024-09-27T17:52:59.676Z',
        updatedAt: '2024-09-27T17:52:59.676Z',
        usageLoggedBy: {
          __typename: 'User',
          id: 'd019de98-1d42-471a-9aac-c591bd2483be',
          firstName: 'Matt',
          lastName: 'Castellini',
        },
        ComponentUsageLog: [
          {
            __typename: 'ComponentUsageLog',
            id: '698c5462-ff84-43bd-a1a2-50583a6204e8',
            componentId: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 12419,
            timeSinceOverhaul: 12419,
            cycleSinceNew: 12064,
            cycleSinceOverhaul: 12064,
            component: {
              __typename: 'AircraftComponent',
              id: '7850e4f7-4d6c-493b-a3c9-4ddb612daf94',
              name: 'Airframe',
              isPrimary: true,
              isIntegral: true,
              serialNumber: '501-0264',
            },
          },
          {
            __typename: 'ComponentUsageLog',
            id: '84ba68b6-dbab-4288-91c4-8ab059cc29e1',
            componentId: 'a566dd78-8de1-4932-abc9-15216221d51e',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 8531.1,
            timeSinceOverhaul: 8531.1,
            cycleSinceNew: 6685,
            cycleSinceOverhaul: 6685,
            component: {
              __typename: 'AircraftComponent',
              id: 'a566dd78-8de1-4932-abc9-15216221d51e',
              name: 'Engine 1',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77104',
            },
          },
          {
            __typename: 'ComponentUsageLog',
            id: '64dc72bd-7c91-4a0b-ae00-1f0bbfacce52',
            componentId: '406c4777-9260-4b4a-a44c-4e67166b1aca',
            usageAsOf: '2024-09-27T04:00:00.000Z',
            totalTimeSinceNew: 4649,
            timeSinceOverhaul: 4649,
            cycleSinceNew: 5096,
            cycleSinceOverhaul: 5096,
            component: {
              __typename: 'AircraftComponent',
              id: '406c4777-9260-4b4a-a44c-4e67166b1aca',
              name: 'Engine 2',
              isPrimary: true,
              isIntegral: true,
              serialNumber: 'PCE-77230',
            },
          },
          {
            __typename: 'ComponentUsageLog',
            id: '665a9dc0-059c-4a38-b95a-b584562f8327',
            componentId: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
            usageAsOf: '2024-06-17T04:00:00.000Z',
            totalTimeSinceNew: 12278.3,
            timeSinceOverhaul: 12278.3,
            cycleSinceNew: 12001,
            cycleSinceOverhaul: 12001,
            component: {
              __typename: 'AircraftComponent',
              id: 'bc35d97c-e2dd-4dcc-b806-af4150c847c5',
              name: 'A/C',
              isPrimary: true,
              isIntegral: false,
              serialNumber: '---',
            },
          },
        ],
      },
    ],
    AircraftUsageParameter: {
      __typename: 'AircraftUsageParameter',
      dailyFlyingTime: 1,
      dailyLandings: 1,
    },
  },
}
