import React from 'react'
import { ListItem, Typography, Avatar } from '@mui/material'
import { AiChatAction, Message } from './types'
import { AiSparklesIcon } from 'src/components/AiSparklesIcon'

interface ChatActionProps {
  action: AiChatAction
  onClick?: (action: AiChatAction) => void
  isActive?: boolean
  disabled?: boolean
  comingSoon?: boolean
}

const ChatAction: React.FC<ChatActionProps> = ({
  action,
  onClick,
  isActive,
  disabled,
  comingSoon,
}) => {
  return (
    <div
      role="button"
      onClick={() => {
        if (!disabled && !isActive) {
          onClick(action)
        }
      }}
      className={`w-full justify-start rounded-xl border border-solid border-blue-100
        bg-gradient-to-br from-white to-blue-50 p-2 text-gray-800 shadow-sm
        ${isActive ? '' : 'h-[120px]'}
        ${
          disabled || isActive
            ? 'cursor-not-allowed opacity-80'
            : 'cursor-pointer transition-shadow hover:shadow-md'
        }`}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <Typography variant="body1" fontWeight={500}>
            {action.name}
          </Typography>
        </div>
        <Typography variant="body2">{action.description}</Typography>
        {comingSoon && <Typography variant="caption">Coming Soon</Typography>}
      </div>
    </div>
  )
}

export default ChatAction
