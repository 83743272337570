import { QueryHookOptions } from '@apollo/client'

import { AircraftFragment } from 'src/fragments/workCardPdfFragment'

import useQuery from '../useQuery'

const GET_COMPLIANCE_LEDGER_BY_ID = gql`
  ${AircraftFragment}
  query getComplianceLedgerByIdForLogbook($id: String!) {
    complianceLedger(id: $id) {
      id
      status
      aircraftId
      timestamp
      workedById
      inspectedById
      aircraftUsageLogId
      aircraftUsageLog {
        id
        usageAsOf
      }
      requestedWorker {
        id
        firstName
        lastName
      }
      requestedInspector {
        id
        firstName
        lastName
      }
      statement {
        id
        statement
      }
      aircraft {
        ...AircraftFragment
      }
      MaintenanceLogbook {
        id
        title
        notes
        status
        trackedBy
        trackedByComponentId
        trackedByComponent {
          id
          name
        }
        AttachedFile {
          id
          file {
            id
            filename
            uploadedBy {
              firstName
              lastName
            }
            url
            originalFilename
          }
          fileId
        }
      }
      complianceActivity(filters: { omitChildren: false }) {
        id
        workHours
        description
        notes
        correctiveAction
        discrepancyItems {
          title
          ataCode
          manufactureCode
          description
          adSbType
          discrepancyStatus
          tags {
            id
            name
          }
          maintenanceNextDue {
            nextDueValue
          }
          trackedByComponentId
          trackedByComponent {
            id
            name
          }
          maintenanceType
        }
        maintenanceItem {
          id
          ataCode
          adSbType
          description
          manufactureCode
          title
          trackedBy
          trackedByComponentId
          trackedByComponent {
            id
            name
          }
          aircraftId
          cadenceType
          cadenceValue
          lastComplianceDate
          aircraftComponent {
            id
            partNumber
            serialNumber
          }
          maintenanceNextDue {
            id
            nextDueValue
          }
        }
        MaintenanceLogbookActivity {
          id
          notes
          hidden
          overrideItemCode
          overrideTitle
        }
        partsTransaction {
          added {
            partNumber
            serialNumber
          }
          removed {
            partNumber
            serialNumber
          }
          removalReason
          installedStatus
        }
      }
      version
      versions {
        id
        version
      }
      isLatest
    }
  }
`

const GET_LOGBOOK_ENTRY_BY_ID = gql`
  ${AircraftFragment}
  query getLogbookEntryById($id: String!) {
    maintenanceLogbook(id: $id) {
      complianceLedger {
        id
        status
        aircraftId
        timestamp
        workedById
        inspectedById
        aircraftUsageLogId
        aircraftUsageLog {
          id
          usageAsOf
        }
        requestedWorker {
          id
          firstName
          lastName
        }
        requestedInspector {
          id
          firstName
          lastName
        }
        statement {
          id
          statement
        }
        aircraft {
          ...AircraftFragment
        }
        MaintenanceLogbook(id: $id) {
          id
          title
          notes
          status
          trackedBy
          trackedByComponentId
          trackedByComponent {
            id
            name
          }
          AttachedFile {
            id
            file {
              id
              filename
              uploadedBy {
                firstName
                lastName
              }
              url
              originalFilename
            }
            fileId
          }
        }
        complianceActivity(filters: { logbookId: $id, omitChildren: false }) {
          id
          workHours
          description
          notes
          correctiveAction
          discrepancyItems {
            title
            ataCode
            manufactureCode
            description
            adSbType
            discrepancyStatus
            tags {
              id
              name
            }
            maintenanceNextDue {
              nextDueValue
            }
            trackedByComponentId
            trackedByComponent {
              id
              name
            }
            maintenanceType
          }
          maintenanceItem {
            id
            ataCode
            adSbType
            description
            manufactureCode
            title
            trackedBy
            trackedByComponentId
            trackedByComponent {
              id
              name
            }
            aircraftId
            cadenceType
            cadenceValue
            lastComplianceDate
            aircraftComponent {
              id
              partNumber
              serialNumber
            }
            maintenanceNextDue {
              id
              nextDueValue
            }
          }
          MaintenanceLogbookActivity {
            id
            notes
            hidden
            overrideItemCode
            overrideTitle
          }
          partsTransaction {
            added {
              partNumber
              serialNumber
            }
            removed {
              partNumber
              serialNumber
            }
            removalReason
            installedStatus
          }
        }
        version
        versions {
          id
          version
        }
        isLatest
      }
    }
  }
`

const useGetLogbookEntryPageData = (
  id: string,
  idType: 'LEDGER_ID' | 'ENTRY_ID',
  options?: QueryHookOptions
) => {
  return useQuery(
    idType === 'LEDGER_ID'
      ? GET_COMPLIANCE_LEDGER_BY_ID
      : GET_LOGBOOK_ENTRY_BY_ID,
    {
      variables: { id },
      ...options,
    }
  )
}

export default useGetLogbookEntryPageData
