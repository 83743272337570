import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, Paper, Typography } from '@mui/material'
import {
  ComplianceActivity,
  ComplianceLedger,
  LogbookPdfOptions,
  PurchaseOrderItem,
} from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal } from 'src/slices/modal'

import Button from 'src/components/MUI/Button'
import CreatableAutocomplete, {
  CreatableAutocompleteOption,
} from 'src/components/MUI/CreatableAutoComplete'
import { UsersDropDownSelector } from 'src/components/MUI/UsersDropDownSelector'
import useQuery from 'src/hooks/useQuery'
import Loading from 'src/components/Loading'
import { useOrgName } from 'src/hooks/useOrgName'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import useUpdateComplianceLedger from 'src/hooks/requests/useUpdateComplianceLedger'
import { navigate, routes } from '@redwoodjs/router'
import { useEffect, useMemo } from 'react'
import getFormErrorArray from 'src/utils/getFormErrorArray'
import Drawer from './MUI/Drawer/Drawer'
import DrawerHeader from './MUI/Drawer/DrawerHeader'
import DrawerActions from './MUI/Drawer/DrawerActions'
import Checkbox from './MUI/Checkbox'
import { defaultLogbookPrintOptions } from '@wingwork/common/src/constants/printLogbookSettings'
import useCreateLogbookEntry from 'src/hooks/requests/useCreateLogbookEntry'
import useGetLogbookEntryPageData from 'src/hooks/requests/useGetLogbookEntryPageData'
import useCreateLogbookPdf from 'src/hooks/requests/useCreateLogbookPdf'
import dayjs from 'dayjs'
import Sentry from 'src/lib/sentry'
import { GET_COMPLIANCE_LEDGER_STATUS } from 'src/pages/BulkComplianceDetailPage/queries'
import { useApolloClient } from '@apollo/client'
import SplitButton from 'src/components/MUI/SplitButton'

const RTS_STATEMENTS = gql`
  query GetRtsStatements($orgSlug: String!) {
    returnToServiceStatements(orgSlug: $orgSlug) {
      id
      statement
    }
  }
`

const CREATE_RTS_STATEMENT = gql`
  mutation CreateRtsStatement($input: CreateReturnToServiceStatementInput!) {
    createReturnToServiceStatement(input: $input) {
      id
    }
  }
`

type CreateLogbookEntryModalProps = {
  propLedgerId?: string
  onRequest?: ({ statementId, requestedWorkerId, requestedInspectorId }) => void
  /** Optionally define what happens when the user clicks backdrop, the close icon, or hits "escape" */
  onBail?: () => void
  onCompleted?: (item: PurchaseOrderItem) => void
}

const modalName = 'createLogbookEntryModal'

const CreateLogbookEntryModal: React.FC<CreateLogbookEntryModalProps> = ({
  propLedgerId,
  onRequest,
  onCompleted,
  onBail,
}) => {
  const dispatch = useDispatch()
  const orgSlug = useOrgName()
  const client = useApolloClient()

  const modalData = useSelector(
    (state) => state.modal.modals?.[modalName]?.data
  )

  // from work order flow, we pass in ledgerId from modalData instead of prop
  const ledgerId = propLedgerId ?? modalData?.ledgerId

  const { data: users } = useGetUsers()

  const {
    data: pageFetchData,
    loading,
    hasLoaded,
    refetch,
  }: {
    data: any
    loading: boolean
    hasLoaded: boolean
  } = useGetLogbookEntryPageData(
    // idType === 'LEDGER_ID' ? ledgerId : entryId,
    // idType
    ledgerId,
    'LEDGER_ID'
  )

  const complianceLedger: ComplianceLedger = pageFetchData?.complianceLedger

  const primaryComponents = useMemo(() => {
    return complianceLedger?.aircraft?.AircraftComponent ?? []
  }, [complianceLedger])

  const itemsGroupedByPrimaryComponentName = React.useMemo(() => {
    return (
      complianceLedger?.complianceActivity?.reduce((acc, value) => {
        const component = primaryComponents.find(
          (component) =>
            component.id === value?.maintenanceItem?.trackedByComponentId
        )
        if (component?.name === undefined) return acc
        if (acc[component.name]) {
          acc[component.name].push(value)
        } else {
          acc[component.name] = [value]
        }
        return acc
      }, {}) ?? {}
    )
  }, [complianceLedger, primaryComponents])

  const getOverrideTitle = (item: ComplianceActivity) => {
    if (item?.MaintenanceLogbookActivity?.overrideTitle) {
      return item?.MaintenanceLogbookActivity?.overrideTitle
    }
    // Return the description for discrepancy items
    if (item?.maintenanceItem?.adSbType === 'DISCREPANCY') {
      return item?.maintenanceItem?.description
    }
    return item?.maintenanceItem?.title
  }

  const getWorkDetailsForForm = (items: ComplianceActivity[]) => {
    return items.map((item) => ({
      notes: '',
      complianceActivityId: item?.id,
      maintenanceLbActivityId: item?.MaintenanceLogbookActivity?.id ?? '',
      overrideItemCode:
        item?.MaintenanceLogbookActivity?.overrideItemCode ??
        `${item?.maintenanceItem?.ataCode}-${item?.maintenanceItem?.manufactureCode}`,
      overrideTitle: getOverrideTitle(item),
      hidden: item?.MaintenanceLogbookActivity?.hidden ?? false,
    }))
  }

  const data = React.useMemo(() => {
    return {
      ...Object.keys(itemsGroupedByPrimaryComponentName).reduce(
        (acc, componentName) => {
          const items = itemsGroupedByPrimaryComponentName[componentName]
          const formKey = `${componentName.toLowerCase()}LbEntry`
          acc[formKey] = {
            workDetails: getWorkDetailsForForm(items),
            aircraftId: complianceLedger?.aircraftId,
            complianceLedgerId: ledgerId,
            trackedBy: componentName.toLocaleUpperCase().replace(' ', '_'),
            trackedByComponentId:
              items?.[0].maintenanceItem?.trackedByComponentId,
            title: `${componentName} Logbook Entry`,
            notes: '',
            logbookId: '',
          }
          return acc
        },
        {}
      ),
    }
  }, [itemsGroupedByPrimaryComponentName])

  const { mutate: updateComplianceLedger, loading: updateLoading } =
    useUpdateComplianceLedger()

  const { createLogbookEntryV2 } = useCreateLogbookEntry()

  const [createRtsStatement] = useMutation(CREATE_RTS_STATEMENT)

  const RETURN_TO_SERVICE_STATEMENT_REQUIRED_ERROR = 'Return to Service Statement is required'


  const schema = useMemo(
    () =>
      yup.object().shape({
        statementId: yup.object({
          value: yup.string().test(
            'value-or-newvalue',
            RETURN_TO_SERVICE_STATEMENT_REQUIRED_ERROR,
            function(value) {
              return Boolean(value || this.parent.newValue)
            }
          )
        }).required(RETURN_TO_SERVICE_STATEMENT_REQUIRED_ERROR),
        requestedWorkerId: yup.string().required('Technician is required'),
        requestedInspectorId: yup
          .string()
          .nullable()
          .test(
            'different-technician-and-inspector',
            'Technician and Inspector cannot be the same user',
            function (value) {
              return (
                !value ||
                !this.parent.requestedWorkerId ||
                this.parent.requestedWorkerId !== value
              )
            }
          ),
        printSize: yup.string().required('Print size is required'),
        header: yup.string().required('Header is required'),
        sorting: yup.string().required('Sorting is required'),
        componentLayout: yup.string().required('Component layout is required'),
        includeDescriptionOfWork: yup.boolean(),
        includeNotes: yup.boolean(),
        includeSubItems: yup.boolean(),
        includeATACodeOnly: yup.boolean(),
        includeTechnicianSignature: yup.boolean(),
        includeInspectorSignature: yup.boolean(),
        includeCorrectiveActionNotes: yup.boolean(),
        includePartRemovalReasonAndInstalledPartStatus: yup.boolean(),
        includeNextDueInformation: yup.boolean(),
        includePDFAttachments: yup.boolean(),
      }),
    [itemsGroupedByPrimaryComponentName]
  )

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultLogbookPrintOptions,
      submitButton: '',
    },
  })

  const formRequestedWorkerId = formMethods.watch('requestedWorkerId')
  const formRequestedInspectorId = formMethods.watch('requestedInspectorId')

  // Set an error on the Inspector field if the Technician and Inspector fields are the same
  useEffect(() => {
    if (
      formRequestedWorkerId &&
      formRequestedInspectorId &&
      formRequestedWorkerId === formRequestedInspectorId
    ) {
      formMethods.setError('requestedInspectorId', {
        message: 'Technician and Inspector cannot be the same user',
      })
    } else {
      formMethods.clearErrors('requestedInspectorId')
    }
  }, [formRequestedWorkerId, formRequestedInspectorId])

  const hasFormErrors = !!Object.keys(formMethods.formState.errors).length
  const formErrors = getFormErrorArray(formMethods.formState.errors)

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const handleBail = () => {
    handleClose()
    onBail?.()
  }

  const activityItemsForTabs = React.useMemo(() => {
    //TODO: Sort the tabs in meaningful order
    return Object.keys(itemsGroupedByPrimaryComponentName).map(
      (componentName) => {
        return {
          label: componentName,
          items: itemsGroupedByPrimaryComponentName[componentName],
          // attachments: getUploadedFiles(
          //   itemsGroupedByPrimaryComponentName[componentName]?.[0]
          //     .maintenanceItem?.trackedByComponentId
          // ),
          formValuesGroupKey: `${componentName.toLowerCase()}LbEntry`,
          logbookTitle: `${componentName} Logbook Entry`,
        }
      }
    )
  }, [itemsGroupedByPrimaryComponentName])

  const handleSubmit = async (values) => {
    console.log('values', values)
    const {
      requestedWorkerId,
      requestedInspectorId,
      statementId: formStatementId,
      submitButton,
      ...logbookPdfOptions
    } = values

    let statementId = formStatementId ? formStatementId.value : null
    if (formStatementId && formStatementId.isNew) {
      const { data: rtsStatementData } = await createRtsStatement({
        variables: {
          input: {
            orgSlug,
            statement: formStatementId.newValue,
          },
        },
      })
      statementId = rtsStatementData.createReturnToServiceStatement.id
    }
    const doSign = submitButton === 'signAndPublish'

    await updateComplianceLedger({
      id: ledgerId,
      requestedWorkerId,
      requestedInspectorId,
      status: doSign ? 'SIGN' : 'COMPLETED_LOGBOOK',
      statementId,
    })
    // navigate(routes.ledgerLogbookEntry({ orgName: orgSlug, ledgerId }))

    // publish the logbook entries

    const promises = activityItemsForTabs.map(async (tab) => {
      const { formValuesGroupKey, label } = tab

      //TODO: Remove this when trackedBy is deprecated fully
      const tempFix = {
        ...data[formValuesGroupKey],
        trackedBy:
          data[formValuesGroupKey]['trackedBy'] === 'ENGINE_3'
            ? 'OTHER'
            : data[formValuesGroupKey]['trackedBy'],
      }
      const { success, upsertMaintenanceLogbook } = await createLogbookEntryV2({
        ...tempFix,
        status: 'PUBLISHED',
        doSign,
        pdfOptions: logbookPdfOptions,
      })

      if (success) {
        toast.success(`${label} Logbook Entry Created`)
      }
    })

    await Promise.all(promises)

    const existingData = client.readQuery({
      query: GET_COMPLIANCE_LEDGER_STATUS,
      variables: { id: ledgerId },
    })

    const updatedData = {
      ...existingData,
      complianceLedger: {
        ...existingData.complianceLedger,
        status: doSign ? 'SIGN' : 'COMPLETED_LOGBOOK',
      },
    }

    client.writeQuery({
      query: GET_COMPLIANCE_LEDGER_STATUS,
      variables: { id: ledgerId },
      data: updatedData,
    })

    dispatch(
      openModal({
        name: 'viewLogbookEntryModal',
      })
    )

    handleClose()
    onRequest?.(values)
  }

  const handleError = (toastId: string) => (e: Error) => {
    Sentry.captureException(e)
    toast.error('Error generating pdf.\nplease contact support.', {
      id: toastId,
    })
  }

  const {
    data: rtsData,
    hasLoaded: rtsStatementsLoaded,
    loading: rtsStatementsLoading,
  } = useQuery(RTS_STATEMENTS, {
    variables: { orgSlug: orgSlug },
  })

  const statementOptions: CreatableAutocompleteOption[] =
    rtsData?.returnToServiceStatements.map((statement) => ({
      label: statement.statement,
      value: statement.id,
    }))

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        handleBail()
      }}
      title="Publish Logbook Entry"
    >
      <DrawerHeader title="Publish Logbook Entry" onClose={handleBail} />
      {loading ? (
        <div className="flex h-full w-[800px] items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="h-full w-[800px] overflow-y-auto p-3">
          <Form
            id="createLogbookEntryForm"
            className="h-full"
            formMethods={formMethods}
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <Typography variant="caption" className="text-blueGrey-600">
                  BULK RETURN TO SERVICE STATEMENT
                </Typography>
                <CreatableAutocomplete
                  isFormField
                  name="statementId"
                  options={statementOptions}
                  growHeight
                />
              </div>
              <div className="flex flex-col gap-2">
                <Typography variant="caption" className="text-blueGrey-600">
                  SIGNATURES
                </Typography>
                <div className="flex gap-2">
                  <UsersDropDownSelector
                    name="requestedWorkerId"
                    label="Technician"
                    users={users?.users || []}
                    className="flex-1"
                  />
                  <UsersDropDownSelector
                    name="requestedInspectorId"
                    label="Inspector"
                    users={users?.users || []}
                    className="flex-1"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Typography variant="caption" className="text-blueGrey-600">
                  PRINT OPTIONS
                </Typography>
                <div className="flex flex-col gap-2">
                  <Checkbox
                    name="includeDescriptionOfWork"
                    label="Include Description of Work"
                  />
                  <Checkbox name="includeNotes" label="Include Notes" />
                  <Checkbox
                    name="includeATACodeOnly"
                    label="Include ATA Code Only"
                  />
                  <Checkbox
                    name="includeTechnicianSignature"
                    label="Include Technician Signature"
                  />
                  <Checkbox
                    name="includeInspectorSignature"
                    label="Include Inspector Signature"
                  />
                  <Checkbox
                    name="includePartRemovalReasonAndInstalledPartStatus"
                    label="Include Part Removal Reason and Installed Part Status"
                  />
                  <Checkbox
                    name="includeNextDueInformation"
                    label="Include Next Due Information"
                  />
                  <Checkbox
                    name="includeDiscrepancies"
                    label="Include Discrepancies"
                  />
                  <Checkbox
                    name="includeCustomTasks"
                    label="Include Custom Tasks"
                  />
                  {/* <Checkbox
                name="includeSubItems"
                checkboxProps={{ disabled: true }}
                label="Include Sub Items"
              />
              <Checkbox
                name="includePDFAttachments"
                checkboxProps={{ disabled: true }}
                label="Include PDF Attachments"
              /> */}
                  <Checkbox
                    name="includeCorrectiveActionNotes"
                    label="Include Corrective Action Notes"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="outlined" color="base" onClick={handleBail}>
            CANCEL
          </Button>
        }
        rightActions={
          <SplitButton
            size="medium"
            disabledTooltip={formErrors[0]}
            initialSelectedIndex={0}
            options={[
              {
                label: 'Publish',
                disabled: hasFormErrors || loading || updateLoading,
                onClick: () => {
                  formMethods.setValue('submitButton', 'publish')
                  formMethods.handleSubmit(handleSubmit)()
                },
              },
              {
                label: 'e-Sign and Publish',
                disabled:
                  hasFormErrors ||
                  loading ||
                  updateLoading ||
                  !(formRequestedWorkerId || formRequestedInspectorId),
                onClick: () => {
                  formMethods.setValue('submitButton', 'signAndPublish')
                  formMethods.handleSubmit(handleSubmit)()
                },
              },
            ]}
          />
        }
      />
    </Drawer>
  )
}

export default CreateLogbookEntryModal
