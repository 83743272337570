import { useState, ReactNode } from 'react'

const useConversation = (initialMessage?: ReactNode) => {
  const [conversation, setConversation] = useState<ReactNode[]>(
    initialMessage ? [initialMessage] : []
  )

  const addMessage = (message: ReactNode) => {
    setConversation((prev) => [...prev, message])
  }

  return { conversation, addMessage }
}

export default useConversation
