import React from 'react'
import useAppLayoutState from '../../../hooks/useAppLayoutSlice'
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AiSparklesIcon } from 'src/components/AiSparklesIcon'
import AiChatInterface from './AiChatInterface'
import { SelectOption } from 'src/components/MUI/SelectDropdown'
import useAircraftRecords from 'src/hooks/requests/useAircraftRecords'
import { useOrgName } from 'src/hooks/useOrgName'

interface AiAssistantDrawerProps {
  title?: string
}

const AiAssistantDrawer: React.FC<AiAssistantDrawerProps> = ({
  title = 'WingWork AI',
}) => {
  const [aircraftOptions, setAircraftOptions] = React.useState<SelectOption[]>(
    []
  )
  const [aircraftId, setAircraftId] = React.useState<string>('')
  const { aiAssistantDrawerOpen, setAiAssistantDrawerOpen } =
    useAppLayoutState()
  const { aircrafts, loadAircraftList } = useAircraftRecords()
  const orgName = useOrgName()

  React.useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
  }, [])

  React.useEffect(() => {
    const options = Object.values(aircrafts).map((aircraft) => ({
      label: aircraft.tailNumber,
      value: aircraft.id,
    }))
    setAircraftOptions(options)
  }, [aircrafts])

  const handleClose = () => {
    setAiAssistantDrawerOpen(false)
  }

  const renderAircraftSelect = () => (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      sx={{ minWidth: 120 }}
    >
      <InputLabel id="aircraft-label">Aircraft</InputLabel>
      <Select
        labelId="aircraft-label"
        id="aircraft-select"
        value={aircraftId}
        onChange={(e) => setAircraftId(e.target.value as string)}
        label="Aircraft"
      >
        <MenuItem value="" disabled>
          <em>Select an aircraft</em>
        </MenuItem>
        {aircraftOptions.map((option) => (
          <MenuItem
            key={option.value.toString()}
            value={option.value.toString()}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  return (
    <Drawer
      anchor="right"
      open={aiAssistantDrawerOpen}
      PaperProps={{ square: false, sx: { width: '100%' } }}
    >
      <div className="flex h-full flex-col">
        <div className="flex items-center justify-between p-2">
          <div className="flex w-full flex-grow items-center gap-2">
            {aircraftId && <AiSparklesIcon width={24} height={24} />}
            <Typography className="whitespace-nowrap" variant="h6">
              {title}
            </Typography>
            {aircraftId && renderAircraftSelect()}
          </div>
          <IconButton onClick={handleClose} edge="end" aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        {!aircraftId ? (
          <div className="relative flex flex-grow flex-col items-center justify-center">
            <div className="absolute top-1/4 flex flex-col items-center gap-4">
              <AiSparklesIcon width={75} height={75} />
              <Typography variant="h5" align="center">
                Select an Aircraft to Begin
              </Typography>
            </div>
            <div className="absolute left-1/2 top-1/2 w-64 -translate-x-1/2 -translate-y-1/2 transform">
              {renderAircraftSelect()}
            </div>
          </div>
        ) : (
          <AiChatInterface aircraftId={aircraftId} />
        )}
      </div>
    </Drawer>
  )
}

export default AiAssistantDrawer
