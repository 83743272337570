import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { FeatureName } from '@wingwork/common/src/constants/features'
import { useEffect } from 'react'

export const GET_FEATURE_SETTING = gql`
  query GetFeatureSetting($input: TenantFeatureQueryInput) {
    tenantFeatures(input: $input) {
      featureName
      enabled
    }
  }
`

// Global cache of enabled features per org
const enabledFeaturesCache: Record<string, Set<FeatureName>> = {}
// Track if we've fetched for an org
const hasFetched: Record<string, boolean> = {}
// Track loading state per org
const loadingOrgs: Record<string, boolean> = {}

const useHasFeature = (featureName: FeatureName, orgSlug: string) => {
  const { loading } = useQuery(GET_FEATURE_SETTING, {
    variables: { input: { orgSlug } },
    skip: !orgSlug || hasFetched[orgSlug] || loadingOrgs[orgSlug],
    onCompleted: (data) => {
      const enabledFeatures = new Set<FeatureName>(
        (data.tenantFeatures ?? [])
          .filter((feature) => feature.enabled)
          .map((feature) => feature.featureName)
      )
      enabledFeaturesCache[orgSlug] = enabledFeatures
      hasFetched[orgSlug] = true
      loadingOrgs[orgSlug] = false
    },
    onError: (error) => {
      console.error(error)
      toast.error(
        'Something went wrong determining your feature settings. Please contact support.'
      )
      loadingOrgs[orgSlug] = false
      hasFetched[orgSlug] = true
    },
  })

  // Set loading state when query starts
  // it prevents race conditions when the query is called multiple times for the same org
  useEffect(() => {
    if (loading && orgSlug) {
      loadingOrgs[orgSlug] = true
    }
    return () => {
      if (loading && orgSlug && loadingOrgs[orgSlug]) {
        loadingOrgs[orgSlug] = false
      }
    }
  }, [loading, orgSlug])

  return {
    hasFeature: enabledFeaturesCache[orgSlug]?.has(featureName) ?? false,
    loading,
  }
}

export default useHasFeature
