import React, { ReactNode, useEffect, useState } from 'react'
import { Typography } from '@mui/material'

interface ConversationDisplayProps {
  conversation: ReactNode[]
}

const ConversationDisplay: React.FC<ConversationDisplayProps> = ({
  conversation,
}) => {
  const [animatedConversation, setAnimatedConversation] = useState<ReactNode[]>(
    []
  )

  useEffect(() => {
    // Only add new messages that aren't already in animatedConversation
    const newMessages = conversation.filter(
      (message, index) =>
        !animatedConversation.includes(message) &&
        index >= animatedConversation.length
    )

    if (newMessages.length > 0) {
      setAnimatedConversation((prev) => [...prev, ...newMessages])
    }
  }, [conversation, animatedConversation])

  return (
    <div className="flex flex-col gap-1">
      {animatedConversation.map((message, index) => (
        <div key={index} className="message-animation">
          {typeof message === 'string' ? (
            <Typography>{message}</Typography>
          ) : (
            message
          )}
        </div>
      ))}
    </div>
  )
}

export default ConversationDisplay
